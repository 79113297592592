import React from "react";
import { ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, Row, Col, Card, FormCheckbox, FormSelect, FormTextarea } from "shards-react";
import Styles from "../componentStyles/styles.module.css";
import { Select } from "antd";

const { Option } = Select;

const FormEmprendimientoUnidades = ({ selectAmenities, unidadAmenities, dataAmenitie, saveUnidades, setField, unidadSelected, cancelFormData, handleChangeCheckbox }) => {
    console.log('Render formUnidades', unidadSelected)
    //var amenitiesSelected = unidadAmenities.map(eachSelectd => { return { value: eachSelectd.id, label: eachSelectd.nombre } });

    return (
        <Col className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Card className="mb-4">
                <ListGroup >
                    <ListGroupItem>
                        <Row>
                            <Col>
                                <Form>
                                    <Row>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="unidadUni">Unidad</label>
                                            <FormInput
                                                id="unidadUni"
                                                placeholder="Unidad..."
                                                value={unidadSelected.unidad}
                                                onChange={(newValue) => setField('unidad', newValue.target.value)}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="descripcionUni">Descripción</label>
                                            <FormTextarea
                                                id="descripcionUni"
                                                placeholder="Descripcion..."
                                                value={unidadSelected.descripcion}
                                                onChange={(newValue) => setField('descripcion', newValue.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" className="form-group">
                                            <label>Tipología de Unidad</label>
                                            <FormSelect
                                                value={unidadSelected.tipologia}
                                                onChange={(newValue) => setField('tipologia', newValue.target.value)}
                                            >
                                                <option>Elige una opción</option>
                                                <option value={'1 Ambiente'}>1 Ambiente</option>
                                                <option value={'2 Ambientes'}>2 Ambientes</option>
                                                <option value={'3 Ambientes'}>3 Ambientes</option>
                                                <option value={'4 Ambientes'}>4 Ambientes</option>
                                                <option value={'Cochera'}>Cochera</option>
                                                <option value={'Baulera'}>Baulera</option>
                                                <option value={'Oficina'}>Oficina</option>
                                                <option value={'Local Comercial'}>Local Comercial</option>
                                            </FormSelect>
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <FormGroup>
                                                <label htmlFor="activoUni">Activo</label>
                                                <FormCheckbox toggle small
                                                    value={unidadSelected.activo}
                                                    checked={unidadSelected.activo}
                                                    onChange={(e) => { handleChangeCheckbox('activo') }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md="6" className="form-group">
                                            <label>Disposición</label>
                                            <FormInput
                                                placeholder="Disposición..."
                                                value={unidadSelected.disposicion}
                                                onChange={(newValue) => setField('disposicion', newValue.target.value)}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <label>Piso</label>
                                            <FormInput
                                                placeholder="Piso..."
                                                value={unidadSelected.piso}
                                                onChange={(newValue) => setField('piso', newValue.target.value)}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <label>Metros Cubiertos</label>
                                                <FormInput
                                                    placeholder="Metros cubiertos..."
                                                    value={unidadSelected.mts_cubiertos}
                                                    onChange={(newValue) => setField('mts_cubiertos', newValue.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <label htmlFor="mtsNoUni">Metros No Cubiertos</label>
                                                <FormInput
                                                    placeholder="Metros no cubiertos..."
                                                    value={unidadSelected.mts_nocubiertos}
                                                    onChange={(newValue) => setField('mts_nocubiertos', newValue.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <FormGroup>
                                        <label>Superficie Amenities m²</label>
                                        <FormInput
                                            placeholder="Superficie Amenities m²..."
                                            value={unidadSelected.superficie_amenities}
                                            onChange={(newValue) => setField('superficie_amenities', newValue.target.value)}
                                        />
                                    </FormGroup>

                                    {/*<FormGroup>
                                        <label htmlFor="amenities">Seleccionar Amenities</label>
                                        <Select
                                            mode="multiple"
                                            defaultValue={amenitiesSelected}
                                            labelInValue
                                            placeholder="Selecciona Amenities..."
                                            filterOption={false}
                                            onChange={(value) => selectAmenities(value)}
                                            style={{ width: '100%' }}
                                        >
                                            {dataAmenitie.map((amenitie, i) =>
                                                <Option value={amenitie.id} key={i}>{amenitie.nombre}</Option>
                                            )}
                                        </Select>
                                    </FormGroup>*/}

                                    <Row>
                                        <Col>
                                            <Button
                                                type="button"
                                                theme="outline-secondary"
                                                onClick={() => cancelFormData(false)}
                                            >
                                                Volver
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                className={Styles.buttonForm}
                                                type="button"
                                                theme="primary"
                                                onClick={() => saveUnidades()}
                                            >
                                                Cargar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup>
            </Card>
        </Col>

    );
}


export default FormEmprendimientoUnidades;