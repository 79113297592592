import decode from 'jwt-decode';
import axios from 'axios';
const TOKEN_NAME = 'pbg_token_web';
const LOGIN_ENDPOINT = '/auth/login/';
//const LOGIN_VALIDA_ENDPOINT = 'login_controller/validartoken/';



axios.interceptors.request.use(function (config) {
    config.metadata = { startTime: new Date() }; return config;
}, function (error) { return Promise.reject(error); });


axios.interceptors.response.use(function (response) {
    response.config.metadata.endTime = new Date()
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime
    return response;
}, function (error) {
    error.config.metadata.endTime = new Date();
    error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
    return Promise.reject(error);
});

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || process.env.REACT_APP_PATH // API server domain
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    async login(user, password) {
        // Get a token from api server using the fetch api
        return axios.post(`${this.domain}` + LOGIN_ENDPOINT, { email: user, password: password })
            .then((responseJson) => {
                //console.log('response', responseJson);
                if (responseJson.data['token'] !== undefined && responseJson.data['token'].length > 0) {
                    this.setToken(responseJson.data['token']);
                }
                return responseJson.data;
            })
            .catch((error) => {
                console.error('post helper error ' + error);
            });
    }

    async loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    getLink(url, params = false) {
        if (params) {
            params += '&X-API-KEY=' + this.getToken();
        } else {
            params = '?X-API-KEY=' + this.getToken();
        }
        url = this.domain + url + params;
        return url;
    }

    get(url, params = false) {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${this.getToken()}` }
        if (params) {
            params['X-API-KEY'] = this.getToken();
        } else {
            params = { 'X-API-KEY': this.getToken() };
        }
        url = this.domain + url;
        var response = axios.get(url, { params: params })
            .then((responseJson) => {
                responseJson.data.duration = responseJson.duration;

                return responseJson.data;
            })
            .catch((error) => {
                console.error('get helper error ' + error);
            });
        return response;
    }

    post(url, data) {
        axios.defaults.headers.common = { 'Authorization': `Bearer ${this.getToken()}` }
        var url_token = this.domain + url + 'X-API-KEY/' + this.getToken();
        console.log('urltoken ', url_token, 'data', data);
        var response = axios.post(url_token, data)
            .then((responseJson) => {
                responseJson.data.duration = responseJson.duration;
                return responseJson.data;
            })
            .catch((error) => {
                console.error('post helper error ' + error);
            });
        return response;
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem(TOKEN_NAME, idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem(TOKEN_NAME)
    }

    logout() {
        // Clear user token and profile data from localStorage
        if (navigator.serviceWorker) {
            navigator.serviceWorker.removeEventListener('message', (event) => { console.log('cerrando listener de service worker') });
            navigator.serviceWorker.getRegistration().then(registration => registration ? registration.unregister() : '');
        }
        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem('routes');
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        return decode(this.getToken());
    }

    isProfile(name) {
        return this.getToken() && this.getProfile().rol === name ? true : false;
    }


    filterRoutes(routes) {
        let finalRoutes = [];
        routes.publicRoutes.map(cada_ruta => finalRoutes.push(cada_ruta));
        if (this.getToken()) {
            routes.privateRoutes.map(cada_ruta => finalRoutes.push(cada_ruta));
        }
        localStorage.setItem('routes', JSON.stringify(finalRoutes));
        return finalRoutes;
    }



}

