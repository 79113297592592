import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { IoIosSearch, IoIosRefresh } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import ModalDelete from "../modalComponent/ModalDelete";

const TableBanners = ({ data, getBanners, getOneBanners, deleteBanners, loadingData }) => {
    console.log('render table banners');
    return (
        <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row style={{ paddingLeft: 10 }}>
                                <h6 className="m-0">Banners</h6>
                                <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => getBanners()} />
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            {loadingData
                                ?
                                <LoadingDataTable />
                                :
                                <TableData
                                    deleteBanners={deleteBanners}
                                    getOneBanners={getOneBanners}
                                    data={data}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const LoadingDataTable = () => (
    <div style={{ textAlign: 'center', padding: 30, marginTop: 8 }}>
        <Spinner animation="border" variant="primary" />
        <span style={{ fontSize: 16, marginLeft: 10 }}>Cargando información...</span>
    </div>
)

const TableData = ({ data, deleteBanners, getOneBanners }) => (
    <table className="table mb-0">
        <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">#</th>
                <th scope="col" className="border-0">Titulo</th>
                <th scope="col" className="border-0">Subtitulo</th>
                <th scope="col" className="border-0">Image</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, i) => (
                <tr key={i}>
                    <td>{row.id}</td>
                    <td>{row.titulo}</td>
                    <td>{row.subtitulo}</td>
                    <td>{row.image_path && <img src={row.image_path} width='100' />}</td>
                    <td>
                        <IoIosSearch size={18} title={'Ver banner'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => getOneBanners(row)} />
                        <ModalDelete body={'¿Desea eliminar banner?'} title={'PBG'} delete={() => deleteBanners(row)} />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default TableBanners;