import React from "react";
import { Button, Container, Row, Col } from "shards-react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import Styles from "../components/componentStyles/styles.module.css";
import TableBanners from "../components/banner/Table";
import FormBanners from "../components/banner/Form";


class Banners extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idSelected: null,
      loadingData: true,
      tipo: '',
      image: {},
      titulo: '', subtitulo: '', bannerEncontrado: [],
      showForm: false,
      oneBannerEncontrado: []
    };

  }

  setField = (name, newValue) => {
    this.setState({ [name]: newValue });
  };

  getBanners = () => {
    //console.log('get clientes', process.env.REACT_APP_PATH);
    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/banners')
        .then((response) => {
          setTimeout(() => {
            this.setState({ bannerEncontrado: response.data, loadingData: false })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  componentDidMount() {

    console.log('componentDidMount de ViewBanners');
    this.getBanners();
  };

  saveBanners = () => {

    this.newBanners();

  };

  newBanners = () => {
    axios.post(process.env.REACT_APP_PATH + '/api/banners', {
      titulo: this.state.titulo,
      id: this.state.idSelected,
      image: this.state.image,
      tipo: this.state.tipo,
      subtitulo: this.state.subtitulo
    })
      .then((response) => {
        console.log(response)
        alert('¡Carga con éxito!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No cargado!');
      });
  };

  getOneBanners = (row) => {
    console.log('id a buscar', row.id);
    axios.get(process.env.REACT_APP_PATH + '/api/oneBanner', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Trayendo datos', response.data.banners);
        var banners = response.data.banners;
        //var digital = response.data.bannerDigital;
        this.setState({
          showForm: true,
          idSelected: banners.id,
          subtitulo: banners.subtitulo,
          titulo: banners.titulo,
          tipo: banners.tipo,
          image: banners.image,
          oneBannerEncontrado: response.data
        });
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
  };

  deleteBanners = (row) => {
    console.log('id a eliminar', row.id);
    axios.delete(process.env.REACT_APP_PATH + '/api/deleteBanner', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Eliminado!', response);
        this.getBanners();
      })
      .catch(function (error) {
        console.log(error)
        alert('Error al Borrar')
      });
  };

  prepareDataForNewBanners = () => {
    this.setState({
      showForm: true, idSelected: null,
      titulo: "", subtitulo: ""
    });
  };

  cancelFormData = () => {
    this.setState({ showForm: false, idSelected: null });
    this.getBanners();
  };

  showButtons = () => {
    if (!this.state.showForm) {
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col>
            </Col>
            <Col md="auto">&nbsp;</Col>
            <Col xs lg="2">
              <Button
                className={Styles.buttonView}
                onClick={() => this.prepareDataForNewBanners()}
                theme="primary" >
                Cargar Banner
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }

    return null;

  };


  render() {
    console.log('render view functioal');
    return (
      <div>
        {!this.state.showForm &&
          <TableBanners
            loadingData={this.state.loadingData}
            getOneBanners={this.getOneBanners}
            data={this.state.bannerEncontrado}
            getBanners={this.getBanners}
            deleteBanners={this.deleteBanners}
          />}

        {this.showButtons()}

        {this.state.showForm &&
          <FormBanners
            saveBanners={this.saveBanners}
            setField={this.setField}
            cancelFormData={this.cancelFormData}
            banners={{ id: this.state.idSelected, tipo: this.state.tipo, image: {}, titulo: this.state.titulo, subtitulo: this.state.subtitulo }} />}
      </div>
    )
  };
};


export default withRouter(Banners);