import React from "react";
import { ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, Row, Col, Card, FormSelect } from "shards-react";
import Styles from "../componentStyles/styles.module.css";
import InputImage from "../digitales/InputImage";



const FormBanners = ({ banners, saveBanners, setField, cancelFormData }) => {
    console.log('Render formBanners')

    return (
        <div style={{ marginTop: 25 }}>
            <Col className="main-content-container px-4 pb-4">
                <Card className="mb-4">
                    <ListGroup >
                        <ListGroupItem>
                            <Row>
                                <Col>
                                    <Form encType="multipart/form-data">
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label>Título</label>
                                                <FormInput
                                                    placeholder="Título..."
                                                    value={banners.titulo}
                                                    onChange={(newValue) => setField('titulo', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <FormGroup>
                                                    <label>Subtitulo</label>
                                                    <FormInput
                                                        placeholder="Subtitulo..."
                                                        value={banners.subtitulo}
                                                        onChange={(newValue) => setField('subtitulo', newValue.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label>Tipo banner</label>
                                                <FormSelect
                                                    placeholder="Selecciona tipo..."
                                                    value={banners.tipo}
                                                    onChange={(newValue) => setField('tipo', newValue.target.value)}
                                                >
                                                    <option value="Home">Home Destacado (3062x1832)</option>
                                                    <option value={'Instagram'}>Instagram (614x614)</option>
                                                    <option value={'homeTop'}>Home Top (3062x1832)</option>
                                                </FormSelect>
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <FormGroup>
                                                    <label>Image</label>
                                                    <InputImage
                                                        digitalData={banners.image}
                                                        showType={false}
                                                        showName={false}
                                                        showButtonCancel={false}
                                                        saveImage={(dataDigital) => setField('image', dataDigital)}
                                                        cancelFormData={{}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button type="button" theme="outline-secondary" onClick={() => cancelFormData()}>Volver</Button>
                                            </Col>
                                            <Col>
                                                <Button className={Styles.buttonForm} type="button" theme="primary" onClick={() => saveBanners()}>Cargar</Button>
                                            </Col>
                                        </Row>

                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </div>

    );
}


export default FormBanners;