import React from "react";
import { Button, Container, Col, Row } from "shards-react";
import axios from 'axios';
import TableDigitales from "../components/digitales/Table";
import InputImage from "../components/digitales/InputImage";
import Styles from "../components/componentStyles/styles.module.css";

class Digitales extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idSelected: null,
      arrayImages: [],
      digitalData: { image_path: '', tipo: '', nombre: '' },
      nombreDigi: '', pathDigi: '', tipoDigi: '', digiEncontrado: [],
      showForm: false,
      loadingData: true,
      oneDigiEncontrado: []
    };

  }

  getDigitales = () => {
    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/digitales')
        .then((response) => {
          setTimeout(() => {
            this.setState({ digiEncontrado: response.data, loadingData: false })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  componentDidMount() {

    console.log('componentDidMount de ViewDigitales');
    this.getDigitales();
  };

  saveDigitales = () => {
    if (this.state.idSelected) {
      this.updateDigitales();
    } else {
      this.newDigitales();
    }
  };

  newDigitales = () => {
    axios.post(process.env.REACT_APP_PATH + '/api/digitales', {
      nombre: this.state.nombreDigi,
      digitales: this.state.arrayImages[0],
      tipo: this.state.tipoDigi
    })
      .then((response) => {
        console.log(response)
        alert('¡Carga con éxito!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No cargado!');
      });
  };

  getOneDigitales = (row) => {
    console.log('id a buscar', row.id);
    axios.get(process.env.REACT_APP_PATH + '/api/oneDigitales', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Trayendo datos', response.data.digitales);
        var digitales = response.data.digitales;
        this.setState({
          digitalData: response.data.digitales,
          showForm: true,
          idSelected: digitales.id,
          nombreDigi: digitales.nombre,
          pathDigi: digitales.path,
          tipoDigi: digitales.tipo,
          oneDigiEncontrado: response.data
        });
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
  };

  deleteDigitales = (row) => {
    console.log('id a eliminar', row.id);
    axios.delete(process.env.REACT_APP_PATH + '/api/deleteDigitales', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('¡Eliminado!', response);
        this.getDigitales();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al Eliminar!')
      });
  };

  updateDigitales = () => {
    var dataToUpdate = {
      params: {
        id: this.state.idSelected,
        nombre: this.state.nombreDigi,
        digitales: this.state.arrayImages,
        tipo: this.state.tipoDigi
      }
    }
    console.log('data a modificar', dataToUpdate)
    axios.put(process.env.REACT_APP_PATH + '/api/updateDigitales', dataToUpdate)
      .then((response) => {
        console.log(response)
        alert('¡Actualizado!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No actualizado!');
      });
  };

  setField = (name, newValue) => {
    this.setState({ [name]: newValue });
  };

  prepareDataForNewDigitales = () => {
    this.setState({ showForm: true, idSelected: null });
  };

  cancelFormData = () => {
    this.setState({ showForm: false, idSelected: null });
    this.getDigitales();
  };

  showButtons = () => {
    if (!this.state.showForm) {
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col></Col>
            <Col md="auto">&nbsp;</Col>
            <Col xs lg="2">
              <Button
                className={Styles.buttonView}
                onClick={() => this.prepareDataForNewDigitales()}
                theme="primary" >
                Cargar Imagenes
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }

    return null;

  };

  saveImage = (imageData) => {
    console.log('data', imageData);
    var auxDataArray = this.state.arrayImages;
    auxDataArray.push(imageData);
    this.setState({ arrayImages: auxDataArray, tipoDigi: imageData.type, nombreDigi: imageData.name }, () => {
      this.saveDigitales();
    });
  };


  render() {
    console.log('render view functional', 'cant images', this.state.arrayImages);
    return (
      <div>
        {!this.state.showForm && <TableDigitales loadingData={this.state.loadingData} getOneDigitales={this.getOneDigitales}
          data={this.state.digiEncontrado} getDigitales={this.getDigitales} deleteDigitales={this.deleteDigitales} />}

        {this.showButtons()}

        {this.state.showForm &&
          <div>
            <InputImage
              digitalData={this.state.digitalData}
              saveImage={this.saveImage}
              cancelFormData={this.cancelFormData}
            />
          </div>
        }
      </div>
    )
  };
};

export default Digitales;
