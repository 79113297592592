import React from "react";
import { withRouter } from "react-router-dom";
import { IoIosRefresh } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import axios from 'axios';
import Styles from "../components/componentStyles/styles.module.css";
import TableConsultas from "../components/consultas/Table";
import { LoadingDataTable } from "../components/clientes/Table";
import ModalDelete from "../components/modalComponent/ModalDelete";
import { Table } from "antd";

import moment from 'moment';
import 'moment/locale/es';

class Consultas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idSelected: null,
      arrayImages: [],
      consultaEncontrada: [],
      showForm: false,
      loadingData: true,
      oneDigiEncontrado: [],
      top: 'none',
      bottom: 'bottomRight'
    };

  }

  getConsultas = () => {
    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/web/emailContact')
        .then((response) => {
          setTimeout(() => {
            this.setState({ consultaEncontrada: response.data, loadingData: false })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  componentDidMount() {

    console.log('componentDidMount de ViewConsultas');
    this.getConsultas();
  };

  deleteConsulta = (row) => {
    console.log('id a eliminar', row.id);
    axios.delete(process.env.REACT_APP_PATH + '/web/deleteEmailContact', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('¡Eliminado!', response);
        this.getConsultas();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al Eliminar!')
      });
  };


  render() {

    const columns = [

      {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
        onFilter: (value, record) => record.nombre.indexOf(value) === 0,
        sorter: (a, b) => a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase()),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        onFilter: (value, record) => record.email.indexOf(value) === 0,
        sorter: (a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Teléfono',
        dataIndex: 'telefono',
        key: 'telefono',
      },

      {
        title: 'Mensaje',
        dataIndex: 'mensaje',
        key: 'mensaje',
        onFilter: (value, record) => record.mensaje.indexOf(value) === 0,
        sorter: (a, b) => a.mensaje.toLowerCase().localeCompare(b.mensaje.toLowerCase()),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Fecha',
        dataIndex: 'created_at',
        key: 'created_at',
        //onFilter: (value, record) => record.created_at.indexOf(value) === 0,
        sorter: (a, b) => moment(a.created_at).valueOf() - moment(b.created_at).valueOf(),
        sortDirections: ['descend', 'ascend'],
        render: (val, row) => `${moment(row.created_at).format('DD/MM/YYYY H:mm')}`
      },

      {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        onFilter: (value, record) => record.url.indexOf(value) === 0,
        sorter: (a, b) => a.url.toLowerCase().localeCompare(b.url.toLowerCase()),
        sortDirections: ['descend', 'ascend'],
        render: (data) => <span style={{ width: 140, display: 'block' }}>{data}</span>
      },
      {
        title: 'Acciones',
        dataIndex: 'id',
        key: 'id',
        render: (value, row) => {
          return (
            <div>
              <ModalDelete body={'¿Desea eliminar?'} title={'PBG'} delete={() => this.deleteConsulta(row)} />
            </div>

          )
        }
      },
    ];

    return (
      <div>
        <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
          <Row>
            <Col>
              <Card small className="mb-4">
                <CardHeader className="border-bottom">
                  <Row style={{ paddingLeft: 10 }}>
                    <h6 className="m-0">Consultas</h6>
                    <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }}
                      onClick={() => this.getConsultas()} />
                  </Row>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  {this.state.loadingData
                    ? <LoadingDataTable />
                    : <Table
                      pagination={{ defaultCurrent: 1, pageSize: 100, total: this.state.consultaEncontrada.length, position: [this.state.top, this.state.bottom] }}
                      dataSource={this.state.consultaEncontrada}

                      columns={columns} />
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>


      </div>
    )
  };
};

export default withRouter(Consultas);
