import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Container, Row, Col } from "shards-react";
import axios from 'axios';
import TableAmenities from "../components/amenities/Table";
import FormAmenities from "../components/amenities/Form";
import Styles from "../components/componentStyles/styles.module.css";

class Amenities extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idSelected: null,
            nombreAme: "", activoAme: false, iconoAme: "", ordenAme: "", showForm: false, oneAmeEncontrado: [], ameEncontrado: [],
            loadingData: true
        }
        this.handleChange = this.handleChange.bind(this);
    };

    setField = (name, newValue) => {
        console.log('set field amenitines', name, newValue);
        this.setState({ [name]: newValue });
    };

    handleChange(nameCheck) {
        const newState = {};
        newState[nameCheck] = !this.state[nameCheck];
        this.setState({ ...this.state, ...newState });
    }

    getAmenities = () => {
        this.setState({ loadingData: true }, () => {
            axios.get(process.env.REACT_APP_PATH + '/api/amenities')
                .then((response) => {
                    setTimeout(() => {
                        this.setState({ ameEncontrado: response.data, loadingData: false })
                    }, 1000);
                })
                .catch(function (error) {
                    console.log(error)
                    alert('¡Error al traer!')
                });
        })
    };

    componentDidMount() {
        console.log('componentDidMount de ViewAmenities');
        this.getAmenities();
    };

    saveAmenities = () => {
        if (this.state.idSelected) {
            this.updateAmenities();
        } else {
            this.newAmenities();
        }
    };

    newAmenities = () => {
        axios.post(process.env.REACT_APP_PATH + '/api/amenities', {
            nombre: this.state.nombreAme,
            activo: this.state.activoAme,
            icono: this.state.iconoAme,
            orden: this.state.ordenAme
        })
            .then((response) => {
                console.log(response)
                this.setState({ nombreAme: '', ordenAme: '', iconoAme: '', activoAme: false })
                alert('¡Carga con éxito!');
            })
            .catch(function (error) {
                console.log(error)
                alert('¡No cargado!');
            });
    }

    getOneAmenities = (row) => {
        console.log('id a buscar', row.id);
        axios.get(process.env.REACT_APP_PATH + '/api/oneAmenities', {
            params: {
                id: row.id
            }
        })
            .then((response) => {
                console.log('Trayendo datos', response.data.amenities);
                var amenities = response.data.amenities;
                this.setState({
                    showForm: true,
                    idSelected: amenities.id,
                    nombreAme: amenities.nombre,
                    activoAme: amenities.activo,
                    iconoAme: amenities.icono,
                    ordenAme: amenities.orden,
                    oneAmeEncontrado: response.data
                });
            })
            .catch(function (error) {
                console.log(error)
                alert('¡Error al traer!')
            });
    };

    deleteAmenities = (row) => {
        console.log('id a eliminar', row.id);
        axios.delete(process.env.REACT_APP_PATH + '/api/deleteAmenities', {
            params: {
                id: row.id
            }
        })
            .then((response) => {
                console.log('¡Eliminado!', response);
                this.getAmenities();
            })
            .catch(function (error) {
                console.log(error)
                alert('¡Error al eliminar!')
            });
    };

    updateAmenities = () => {
        var dataToUpdate = {

            id: this.state.idSelected,
            nombre: this.state.nombreAme,
            activo: this.state.activoAme,
            icono: this.state.iconoAme,
            orden: this.state.ordenAme,

        }
        console.log('data a modificar', dataToUpdate)
        axios.put(process.env.REACT_APP_PATH + '/api/updateAmenities', dataToUpdate)
            .then((response) => {
                console.log(response)
                alert('¡Actualizado!');
            })
            .catch(function (error) {
                console.log(error)
                alert('¡No actualizado!');
            });
    };

    prepareDataForNewAmenities = () => {
        this.setState({ showForm: true, idSelected: null });
    }

    cancelFormData = () => {
        this.setState({ showForm: false, idSelected: null });
        this.getAmenities();
    }

    showButtons = () => {
        if (!this.state.showForm) {
            return (
                <Container fluid className="main-content-container px-4 pb-4">
                    <Row>
                        <Col></Col>
                        <Col md="auto">&nbsp;</Col>
                        <Col xs lg="2">
                            <Button
                                className={Styles.buttonView}
                                onClick={() => this.prepareDataForNewAmenities()}
                                theme="primary" >
                                Nueva Amenity
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )
        }

        return null;

    };

    render() {
        console.log('render view functional');
        return (
            <div>
                {!this.state.showForm && <TableAmenities loadingData={this.state.loadingData} getOneAmenities={this.getOneAmenities} data={this.state.ameEncontrado} getAmenities={this.getAmenities} deleteAmenities={this.deleteAmenities} />}

                {this.showButtons()}

                {this.state.showForm &&
                    <FormAmenities saveAmenities={this.saveAmenities} setField={this.setField}
                        handleChangeCheckbox={this.handleChange}
                        handleFileChange={this.handleFileChange}
                        amenities={{
                            nombreAme: this.state.nombreAme, activoAme: this.state.activoAme, iconoAme: this.state.iconoAme,
                            ordenAme: this.state.ordenAme
                        }}
                        cancelFormData={this.cancelFormData}
                    />}
            </div>
        )
    };

}

export default withRouter(Amenities);