import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { IoIosSearch, IoIosRefresh } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import ModalDelete from "../modalComponent/ModalDelete";

const TableUsuarios = ({ data, getUsuarios, getOneUsuarios, deleteUsuarios , loadingData}) => {
    console.log('render table Usuarios');
    return (
        <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row style={{ paddingLeft: 10 }}>
                                <h6 className="m-0">Usuarios</h6>
                                <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => getUsuarios()} />
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            {loadingData
                                ?
                                <LoadingDataTable />
                                :
                                <TableData
                                    deleteUsuarios={deleteUsuarios}
                                    getOneUsuarios={getOneUsuarios}
                                    data={data} 
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const LoadingDataTable = () => (
    <div style={{ textAlign: 'center', padding: 30, marginTop: 8 }}>
        <Spinner animation="border" variant="primary" />
        <span style={{ fontSize: 16, marginLeft: 10 }}>Cargando información...</span>
    </div>
)

const TableData = ({data ,getOneUsuarios, deleteUsuarios}) => (
    <table className="table mb-0">
        <thead className="bg-light" >
            <tr>
                <th scope="col" className="border-0">#</th>
                <th scope="col" className="border-0">E-mail</th>
                <th scope="col" className="border-0">Password</th>
                <th scope="col" className="border-0">Último Acceso</th>
                <th scope="col" className="border-0">Activo</th>
                <th scope="col" className="border-0">Creado</th>
                <th scope="col" className="border-0">Modificado</th>
                <th scope="col" className="border-0">Acciones</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, i) => (
                <tr key={i}>
                    <td>{row.id}</td>
                    <td>{row.email}</td>
                    <td>{row.password}</td>
                    <td>{row.last_login}</td>
                    <td>{row.activo}</td>
                    <td>{row.created_at}</td>
                    <td>{row.updated_at}</td>
                    <td>
                        <IoIosSearch size={18} title={'Ver usuario'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => getOneUsuarios(row)} /> 
                        <ModalDelete body={'¿Desea eliminar usuario?'} title={'PBG'} delete={() => deleteUsuarios(row)} />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)


export default TableUsuarios;