import React from "react";
import { ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, Row, Col, Card, FormCheckbox, FormSelect, FormTextarea } from "shards-react";
import { Select } from 'antd';
import Styles from "../componentStyles/styles.module.css";
import PlacesAutocomplete from "./PlacesAutoComplete";


const { Option } = Select;

const FormEmprendimiento = ({ emprendimiento, cantRecorridos, nuevoRecorrido, setRecorrido, selectAmenities, dataAmenitie, setAddress, saveEmprendimiento, handleChangeCheckbox, setField, cancelFormData, emprendimientoAmenities }) => {
    console.log('Render formEmprendimiento', emprendimiento)
    var amenitiesSelected = emprendimientoAmenities.map(eachSelectd => {
        return { value: eachSelectd.id, label: eachSelectd.nombre }
    });

    return (
        <div style={{ marginTop: 25 }}>
            <Col className="main-content-container px-4 pb-4">
                <Card className="mb-4">
                    <ListGroup >
                        <ListGroupItem>
                            <Row>
                                <Col>
                                    <Form>
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="nombreEmp">Nombre</label>
                                                <FormInput
                                                    id="nombreEmp"
                                                    placeholder="Nombre..."
                                                    value={emprendimiento.nombreEmp}
                                                    onChange={(newValue) => setField('nombreEmp', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="linkEmp">Link</label>
                                                <FormInput
                                                    id="linkEmp"
                                                    placeholder="Link..."
                                                    value={emprendimiento.friendlyEmp}
                                                    onChange={(newValue) => setField('friendlyEmp', newValue.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label >Fecha de entrega</label>
                                                <FormInput
                                                    type="date"
                                                    placeholder="Fecha de entrega..."
                                                    value={emprendimiento.fecha_entrega}
                                                    onChange={(newValue) => setField('fecha_entrega', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label>Estado del Emprendimiento</label>
                                                <FormSelect
                                                    placeholder="Selecciona el estado..."
                                                    value={emprendimiento.estadoEmprendimiento}
                                                    onChange={(newValue) => setField('estadoEmprendimiento', newValue.target.value)}
                                                >
                                                    <option>Elige una opción</option>
                                                    <option value={'En pozo'}>En pozo</option>
                                                    <option value={'En obra'}>En obra</option>
                                                    <option value={'En obra avanzada'}>En obra avanzada</option>
                                                    <option value={'Finalizado'}>Finalizado</option>
                                                </FormSelect>
                                            </Col>
                                        </Row>

                                        <FormGroup>
                                            <Row>
                                                <Col md="3" className="form-group">
                                                    <label htmlFor="homeEmp">Nuevo lanzamiento</label>
                                                    <FormCheckbox small toggle
                                                        id="nuevo_lanzamiento"
                                                        placeholder="Home..."
                                                        value={emprendimiento.nuevo_lanzamiento}
                                                        checked={emprendimiento.nuevo_lanzamiento}
                                                        onChange={(e) => { handleChangeCheckbox('nuevo_lanzamiento') }}
                                                    />
                                                </Col>
                                                <Col md="3" className="form-group">
                                                    <label htmlFor="homeEmp">Home Destacado</label>
                                                    <FormCheckbox small toggle
                                                        id="homeDestacadoEmp"
                                                        placeholder="Home..."
                                                        value={emprendimiento.homeDestacado}
                                                        checked={emprendimiento.homeDestacado}
                                                        onChange={(e) => { handleChangeCheckbox('homeDestacado') }}
                                                    />
                                                </Col>
                                                <Col md="3" className="form-group">
                                                    <label htmlFor="homeEmp">Home</label>
                                                    <FormCheckbox small toggle
                                                        id="homeEmp"
                                                        placeholder="Home..."
                                                        value={emprendimiento.homeEmp}
                                                        checked={emprendimiento.homeEmp}
                                                        onChange={(e) => { handleChangeCheckbox('homeEmp') }}
                                                    />
                                                </Col>
                                                <Col md="3" className="form-group">
                                                    <label>Apto profesional</label>
                                                    <FormCheckbox small toggle
                                                        id="apto"
                                                        placeholder="Apto profesional..."
                                                        value={emprendimiento.aptoProfesional}
                                                        checked={emprendimiento.aptoProfesional}
                                                        onChange={(e) => { handleChangeCheckbox('aptoProfesional') }}
                                                    />
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                        <Row>
                                            <Col md="12" className="form-group">
                                                <label>Txt Descuento Usuario (Nueva inversión)</label>
                                                <FormInput
                                                    placeholder="15% SOBRE EL VALOR DE LISTA"
                                                    value={emprendimiento.descuento_usuario}
                                                    onChange={(newValue) => setField('descuento_usuario', newValue.target.value)}
                                                />
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label>Título Inicial</label>
                                                <FormInput
                                                    placeholder="Titulo Inicial..."
                                                    value={emprendimiento.tituloInicial}
                                                    onChange={(newValue) => setField('tituloInicial', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label>Descripción Inicial</label>
                                                <FormTextarea
                                                    placeholder="Descripcion Inicial..."
                                                    value={emprendimiento.descripcionInicial}
                                                    onChange={(newValue) => setField('descripcionInicial', newValue.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label>Título 1 Barrio</label>
                                                <FormInput
                                                    placeholder="Titulo Barrio..."
                                                    value={emprendimiento.tituloBarrio}
                                                    onChange={(newValue) => setField('tituloBarrio', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label>Título 2 Barrio</label>
                                                <FormInput
                                                    placeholder="Título 2 Barrio..."
                                                    value={emprendimiento.subtituloBarrio}
                                                    onChange={(newValue) => setField('subtituloBarrio', newValue.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label>Título 3 Barrio</label>
                                                <FormInput
                                                    placeholder="Título 3 Barrio..."
                                                    value={emprendimiento.titulo3Barrio}
                                                    onChange={(newValue) => setField('titulo3Barrio', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col className="form-group">
                                                <label>Descripción Barrio</label>
                                                <FormTextarea
                                                    placeholder="Descripcion Barrio..."
                                                    value={emprendimiento.descripcionBarrio}
                                                    onChange={(newValue) => setField('descripcionBarrio', newValue.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label>Título Tipología</label>
                                                <FormInput
                                                    placeholder="Titulo Tipología..."
                                                    value={emprendimiento.tituloTipologia}
                                                    onChange={(newValue) => setField('tituloTipologia', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label>Descripción Tipología</label>
                                                <FormTextarea
                                                    placeholder="Descripcion Tipología..."
                                                    value={emprendimiento.descripcionTipologia}
                                                    onChange={(newValue) => setField('descripcionTipologia', newValue.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md="6" className="form-group">
                                                <label>Título comercial tipologiás</label>
                                                <FormInput
                                                    placeholder="Titulo comercial..."
                                                    value={emprendimiento.tituloComercialTipologia}
                                                    onChange={(newValue) => setField('tituloComercialTipologia', newValue.target.value)}
                                                />
                                            </Col>

                                            <Col md="6" className="form-group">
                                                <label>Texto comercial tipologiás</label>
                                                <FormTextarea
                                                    placeholder="Texto comercial tipologías"
                                                    value={emprendimiento.tipologiasTxtComercial}
                                                    onChange={(newValue) => setField('tipologiasTxtComercial', newValue.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <FormGroup>
                                            <label htmlFor="amenities">Seleccionar Amenities</label>
                                            <Select
                                                mode="multiple"
                                                defaultValue={amenitiesSelected}
                                                labelInValue
                                                placeholder="Selecciona Amenities..."
                                                filterOption={false}
                                                onChange={(value) => selectAmenities(value)}
                                                style={{ width: '100%' }}
                                            >
                                                {dataAmenitie.map((amenitie, i) =>
                                                    <Option value={amenitie.id} key={i}>{amenitie.nombre}</Option>
                                                )}
                                            </Select>
                                        </FormGroup>

                                        <FormGroup>
                                            <label >Seleccionar Ubicación</label>
                                            <PlacesAutocomplete
                                                setAddress={setAddress}
                                                domicilio={
                                                    emprendimiento.address != undefined && emprendimiento.address.length
                                                        ?
                                                        emprendimiento.address[0]
                                                        :
                                                        {}}
                                            />
                                        </FormGroup>

                                        <Row>
                                            {cantRecorridos.map((obj, i) =>
                                                <Col md="12" className="form-group">
                                                    <Row>
                                                        <Col md="6" className="form-group">
                                                            <label>Url Recorrido #{i}</label>
                                                            <FormInput
                                                                placeholder="url"
                                                                value={obj.url}
                                                                onChange={(newValue) => setRecorrido({ url: newValue.target.value, descripcion: obj.descripcion }, i)}
                                                            />
                                                        </Col>
                                                        <Col md="6" className="form-group">
                                                            <label>Descripción #{i}</label>
                                                            <FormInput
                                                                placeholder="descripción"
                                                                value={obj.descripcion}
                                                                onChange={(newValue) => setRecorrido({ url: obj.url, descripcion: newValue.target.value }, i)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <Button onClick={() => nuevoRecorrido()}>Agregar recorrido</Button>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Button
                                                    type="button"
                                                    theme="outline-secondary"
                                                    onClick={() => cancelFormData()}
                                                >
                                                    Volver
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    className={Styles.buttonForm}
                                                    type="button"
                                                    theme="primary"
                                                    onClick={() => saveEmprendimiento()}
                                                >
                                                    Cargar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </div >
    );
}


export default FormEmprendimiento;