import React from "react";
import { withRouter } from "react-router-dom";
import AuthService from "../utils/AuthService";

const Auth = new AuthService();

class Logout extends React.Component {

  constructor(props) {
    super(props);

  }

  componentDidMount() {
    Auth.logout();
    this.props.history.push('/login');
  }


  render() {
    return (
      <p>Estamos cerrando sesión</p>
    );
  }
}
export default withRouter(Logout);