import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row, Container } from "shards-react";
import axios from 'axios';
import TableUnidades from "../components/unidades/Table";
import FormUnidades from "../components/unidades/Form";
import UnidadCuotas from "../views/ViewUnidadCuotas";
import Styles from "../components/componentStyles/styles.module.css";
import Clientes from "./ViewClientes";


class Unidades extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idSelected: null,
      showFormUnidadCuotas: false,
      showFormClientes: false,
      descripcionUni: '', unidadUni: '', activoUni: false, mtsUni: '', mtsNoUni: '', uniEncontrada: [],
      showForm: false,
      oneClienteUnidad: [],
      oneUniEncontrada: [],
      loadingData: true
    };
    this.handleChange = this.handleChange.bind(this);
  }

  getUnidades = () => {
    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/unidades', {
        params: {
          emprendimiento_id: this.props.emprendimiento != undefined && this.props.emprendimiento.id ? this.props.emprendimiento.id : 0,
          cliente: this.props.cliente != undefined && this.props.cliente.id ? this.props.cliente.id : 0
        }
      })
        .then((response) => {
          setTimeout(() => {
            this.setState({ uniEncontrada: response.data, loadingData: false })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  componentDidMount() {

    console.log('componentDidMount de ViewUnidades');
    this.getUnidades();
  };

  saveUnidades = () => {
    if (this.state.idSelected) {
      this.updateUnidades();
    } else {
      this.newUnidades();
    }
  };

  newUnidades = () => {
    axios.post(process.env.REACT_APP_PATH + '/api/unidades', {
      params: {
        emprendimiento_id: this.props.emprendimiento.id,
        descripcion: this.state.descripcionUni,
        unidad: this.state.unidadUni,
        activo: this.state.activoUni,
        mts_cubiertos: this.state.mtsUni,
        mts_nocubiertos: this.state.mtsNoUni
      }
    })
      .then((response) => {
        console.log(response)
        alert('¡Carga con éxito!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No cargado!');
      });
  }

  getOneUnidades = (row) => {
    console.log('id a buscar', row.id);
    axios.get(process.env.REACT_APP_PATH + '/api/oneUnidades', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Trayendo datos', response.data.unidades);
        var unidades = response.data.unidades;
        this.setState({
          showForm: true,
          idSelected: unidades.id,
          descripcionUni: unidades.descripcion,
          unidadUni: unidades.unidad,
          activoUni: unidades.activo,
          mtsUni: unidades.mts_cubiertos,
          mtsNoUni: unidades.mts_nocubiertos,
          oneUniEncontrada: response.data
        });
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
  };

  deleteUnidades = (row) => {
    console.log('id a eliminar', row.id);
    axios.delete(process.env.REACT_APP_PATH + '/api/deleteUnidades', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Borrado', response);
        this.getUnidades();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al Borrar!')
      });
  };

  updateUnidades = () => {
    var dataToUpdate = {
      params: {
        id: this.state.idSelected,
        descripcion: this.state.descripcionUni,
        unidad: this.state.unidadUni,
        activo: this.state.activoUni,
        mts_cubiertos: this.state.mtsUni,
        mts_nocubiertos: this.state.mtsNoUni
      }
    }
    console.log('data a modificar', dataToUpdate)
    axios.put(process.env.REACT_APP_PATH + '/api/updateUnidades', dataToUpdate)
      .then((response) => {
        console.log(response)
        alert('¡Actualizado!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No actualizado!');
      });
  };

  setField = (name, newValue) => {
    this.setState({ [name]: newValue });
  };

  handleChange(nameCheck) {
    const newState = {};
    newState[nameCheck] = !this.state[nameCheck];
    this.setState({ ...this.state, ...newState });
  }

  prepareDataForNewUnidades = () => {
    this.setState({ showForm: true, idSelected: null });
  }

  cancelBackData = () => {    //<------ para volver a ViewEmprendimientos
    this.props.setFormAltaUnidadShow(false);
  }

  showButtons = () => {
    if (!this.state.showForm) {
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col>
              <Button
                className={Styles.buttonBack}
                onClick={() => this.cancelBackData()}
                theme="secondary" >
                Volver
              </Button>
            </Col>
            <Col md="auto">&nbsp;</Col>
            <Col xs lg="2">
              <Button
                className={Styles.buttonView}
                onClick={() => this.prepareDataForNewUnidades()}
                theme="primary" >
                Nueva Unidad
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }

    return null;

  };

  showFormUnidadCuotas = (row) => {
    this.setState({ showFormUnidadCuotas: true, showForm: false, idSelected: row, newUnidadCuotas: row.id });
  };

  showFormClientes = (row) => {
    console.log('por traer data de los clientes de una unidad');
    axios.get(process.env.REACT_APP_PATH + '/api/showClients', {
      params: {
        unidad: row.id
      }
    })
      .then((response) => {
        console.log('response', response);
        this.setState({ oneClienteUnidad: response.data, clienteSelected: row, showFormClientes: true })
      })
      .catch(function (error) {
        console.log(error)
        alert('Error al traer')
      });
  };



  render() {
    console.log('render view functional ViewUnidades, cliente:', this.props.cliente);
    return (
      <div>
        {!this.state.showForm && !this.state.showFormClientes && !this.state.showFormUnidadCuotas &&
          <TableUnidades
            emprendimiendo={this.props.emprendimiento}
            loadingData={this.state.loadingData}
            showFormClientes={this.showFormClientes}
            showFormUnidadCuotas={this.showFormUnidadCuotas}
            cliente={this.props.cliente}
            getOneUnidades={this.getOneUnidades}
            data={this.state.uniEncontrada}
            getUnidades={this.getUnidades}
            deleteUnidades={this.deleteUnidades}
          />}

        {!this.state.showForm && !this.state.showFormClientes && !this.state.showFormUnidadCuotas && this.showButtons()}

        {this.state.showForm &&
          <FormUnidades
            handleChangeCheckbox={this.handleChange}
            saveUnidades={this.saveUnidades}
            setField={this.setField}
            cancelFormData={this.cancelFormData}
            unidades={{
              id: this.state.idSelected, descripcionUni: this.state.descripcionUni,
              activoUni: this.state.activoUni, unidadUni: this.state.unidadUni, mtsUni: this.state.mtsUni, mtsNoUni: this.state.mtsNoUni
            }}
          />}
      </div>
    )
  };
};

export default withRouter(Unidades);