import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { IoIosSearch, IoIosRefresh } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import ModalDelete from "../modalComponent/ModalDelete";

const TableAmenities = ({ data, getAmenities, getOneAmenities, deleteAmenities, loadingData }) => {
    console.log('render table amenities');
    return (
        <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row style={{ paddingLeft: 10 }}>
                                <h6 className="m-0">Amenities</h6>
                                <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => getAmenities()} />
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            {loadingData
                                ?
                                <LoadingDataTable />
                                :
                                <TableData
                                    deleteAmenities={deleteAmenities}
                                    getOneAmenities={getOneAmenities}
                                    data={data}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const LoadingDataTable = () => (
    <div style={{ textAlign: 'center', padding: 30, marginTop: 8 }}>
        <Spinner animation="border" variant="primary" />
        <span style={{ fontSize: 16, marginLeft: 10 }}>Cargando información...</span>
    </div>
)

const TableData = ({ data, deleteAmenities, getOneAmenities }) => (
    <table className="table mb-0">
        <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">#</th>
                <th scope="col" className="border-0">Nombre</th>
                <th scope="col" className="border-0">Activo</th>
                <th scope="col" className="border-0">Icono</th>
                <th scope="col" className="border-0">Orden</th>
                <th scope="col" className="border-0">Acciones</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, i) => (
                <tr key={i}>
                    <td>{row.id}</td>
                    <td>{row.nombre}</td>
                    <td>{row.activo}</td>
                    <td>{row.image_path && <img src={row.image_path} width='100' />}</td>
                    <td>{row.orden}</td>
                    <td>
                        <IoIosSearch size={18} title={'Ver amenity'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => getOneAmenities(row)} />
                        <ModalDelete body={'¿Desea eliminar Amenity?'} title={'PBG'} delete={() => deleteAmenities(row)} />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default TableAmenities;