import React from "react";
import { ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, Row, Col, Card, FormCheckbox } from "shards-react";
import Styles from "../componentStyles/styles.module.css";


const FormUsuarios = ({ usuarios, saveUsuarios, setField, cancelFormData , handleChangeCheckbox }) => {
    console.log('Render formUsuarios')
    
    return (
        <div style={{ marginTop: 25 }}>
            <Col className="main-content-container px-4 pb-4">
                <Card className="mb-4">
                    <ListGroup >
                        <ListGroupItem>
                            <Row>
                                <Col>
                                    <Form>
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="emailUsu">E-mail</label>
                                                <FormInput
                                                    id="emailUsu"
                                                    placeholder="E-mail..."
                                                    value={usuarios.emailUsu}
                                                    onChange={(newValue) => setField('emailUsu', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label>Password</label>
                                                <FormInput
                                                    type="password"
                                                    placeholder="Password..."
                                                    value={usuarios.passwordUsu}
                                                    onChange={(newValue) => setField('passwordUsu', newValue.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <label htmlFor="activoUsu">Activo</label>
                                            <FormCheckbox small toggle
                                                id="activoUsu"
                                                placeholder="Activo..."
                                                value={usuarios.activoUsu}
                                                checked={usuarios.activoUsu}
                                                onChange={(e) => { handleChangeCheckbox('activoUsu') }}
                                            />
                                        </FormGroup>
                                        <Row>
                                            <Col>
                                                <Button
                                                    type="button"
                                                    theme="outline-secondary"
                                                    onClick={() => cancelFormData()}
                                                >
                                                    Volver
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    className={Styles.buttonForm}
                                                    type="button"
                                                    theme="primary"
                                                    onClick={() => saveUsuarios()}
                                                >
                                                    Cargar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </div>

    );
}


export default FormUsuarios;