import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { Spinner } from 'react-bootstrap';
import { IoIosSearch, IoIosHome, IoIosRefresh, IoIosCamera, IoIosMail } from "react-icons/io";
import ModalDelete from "../modalComponent/ModalDelete";


const TableEmprendimientos = ({ data, getEmprendimiento, askForMailing, loadingData, getOneEmprendimiento, deleteEmprendimiento, showFormUnidades, showFormDigitales }) => {
    console.log('render table emprendimiento');
    return (
        <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row style={{ paddingLeft: 10 }}>
                                <h6 className="m-0">Emprendimientos</h6>
                                <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => getEmprendimiento()} />
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            {loadingData
                                ?
                                <LoadingDataTable />
                                :
                                <TableData
                                    askForMailing={askForMailing}
                                    showFormDigitales={showFormDigitales}
                                    deleteEmprendimiento={deleteEmprendimiento}
                                    getOneEmprendimiento={getOneEmprendimiento}
                                    showFormUnidades={showFormUnidades}
                                    data={data}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const LoadingDataTable = () => (
    <div style={{ textAlign: 'center', padding: 30, marginTop: 8 }}>
        <Spinner animation="border" variant="primary" />
        <span style={{ fontSize: 16, marginLeft: 10 }}>Cargando información...</span>
    </div>
)

const TableData = ({ data, askForMailing, showFormUnidades, getOneEmprendimiento, deleteEmprendimiento, showFormDigitales }) => (
    <table className="table mb-0" >
        <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">#</th>
                <th scope="col" className="border-0">Nombre</th>
                <th scope="col" className="border-0">Link</th>
                <th scope="col" className="border-0">Fecha de Entrega</th>
                <th scope="col" className="border-0">Estado</th>
                <th scope="col" className="border-0">Home</th>
                <th scope="col" className="border-0">Acciones</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, i) => (
                <tr key={i}>
                    <td>{row.id}</td>
                    <td>{row.nombre}</td>
                    <td>{row.friendly_url}</td>
                    <td>{row.fecha_entrega}</td>
                    <td>{row.estado_emprendimiento}</td>
                    <td>{row.home}</td>
                    <td>
                        <IoIosHome size={18} title={'Unidades'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => showFormUnidades(row)} />
                        <IoIosCamera size={20} title={'Imágenes'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => showFormDigitales(row)} />
                        <IoIosSearch size={18} title={'Ver emprendimiento'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => getOneEmprendimiento(row)} />
                        <IoIosMail size={18} title={'Email de actualización de informe'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => askForMailing(row)} />
                        <ModalDelete body={'¿Desea eliminar el emprendimiento?'} title={'PBG'} delete={() => deleteEmprendimiento(row)} />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default TableEmprendimientos;