import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Container, Col, Row } from "shards-react";
import axios from 'axios';
import TableDigitales from "../components/digitales/Table";
import InputImage from "../components/digitales/InputImage";
import Styles from "../components/componentStyles/styles.module.css";


class UnidadDigitales extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idSelected: null,
      files: [],
      emprendimientoSelected: {},
      nombreDigi: '', pathDigi: '', tipoDigi: '', digiEncontrado: [],
      showForm: false,
      loadingData: true,
      oneDigiEncontrado: []
    };

  }

  getDigitales = () => {
    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/digitales', {
        params: { unidad_id: this.props.match.params.id }
      })
        .then((response) => {
          setTimeout(() => {
            console.log('response', response.data)
            this.setState({ digiEncontrado: response.data.unidadDigital, loadingData: false })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  componentDidMount() {

    console.log('componentDidMount de ViewDigitales');
    this.getDigitales();
  };

  saveDigitales = () => {
    this.newDigitales();

  };

  newDigitales = () => {
    if (this.state.files == undefined) {
      axios.post(process.env.REACT_APP_PATH + '/api/digitales', {
        nombre: this.state.nombreDigi,
        id: this.state.idSelected,
        digitales: [],
        tipo: this.state.tipoDigi,
        unidades_id: this.props.match.params.id
      })
        .then((response) => {
          console.log(response)

          alert('¡Carga con éxito!');
          this.setState({ showForm: false });
          this.getDigitales();

        })
        .catch(function (error) {
          console.log(error)
          alert('¡No cargado!');
        });
    } else {
      this.state.files.map((eachFile, i) => {

        axios.post(process.env.REACT_APP_PATH + '/api/digitales', {
          nombre: this.state.nombreDigi,
          id: this.state.idSelected,
          digitales: eachFile,
          tipo: this.state.tipoDigi,
          unidades_id: this.props.match.params.id
        })
          .then((response) => {
            console.log(response)
            if (i == this.state.files.length - 1) {//SI ES EL ULTIMO ARCHIVO
              alert('¡Carga con éxito!');
              this.setState({ showForm: false });
              this.getDigitales();
            }
          })
          .catch(function (error) {
            console.log(error)
            alert('¡No cargado!');
          });
      });
    }
  };

  getOneDigitales = (row) => {
    console.log('id a buscar', row.id);
    axios.get(process.env.REACT_APP_PATH + '/api/oneDigitales', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Trayendo datos', response.data.digitales);
        var digitales = response.data.digitales;
        this.setState({
          digitalData: response.data.digitales,
          showForm: true,
          idSelected: digitales.id,
          nombreDigi: digitales.nombre,
          pathDigi: digitales.path,
          tipoDigi: digitales.tipo,
          oneDigiEncontrado: response.data
        });
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
  };

  deleteDigitales = (row) => {
    console.log('id a eliminar', row.id);
    axios.delete(process.env.REACT_APP_PATH + '/api/deleteDigitales', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('¡Eliminado!', response);
        this.getDigitales();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al Eliminar!')
      });
  };

  updateDigitales = () => {
    var dataToUpdate = {
      params: {
        id: this.state.idSelected,
        nombre: this.state.nombreDigi,
        path: this.state.pathDigi,
        tipo: this.state.tipoDigi
      }
    }
    console.log('data a modificar', dataToUpdate)
    axios.put(process.env.REACT_APP_PATH + '/api/updateDigitales', dataToUpdate)
      .then((response) => {
        console.log(response)
        alert('¡Actualizado!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No actualizado!');
      });
  };

  setField = (name, newValue) => {
    this.setState({ [name]: newValue });
  };

  prepareDataForNewDigitales = () => {
    this.setState({ showForm: true, idSelected: null });
  };

  cancelFormData = () => {
    this.setState({ showForm: false, idSelected: null });
    this.getDigitales();
  };

  cancelBackData = () => {    //<------ para volver a ViewUnidades
    this.props.history.goBack();
  }

  showButtons = () => {
    if (!this.state.showForm) {
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col>
              <Button
                className={Styles.buttonBack}
                onClick={() => this.cancelBackData()}
                theme="secondary" >
                Volver
              </Button>
            </Col>
            <Col md="auto">&nbsp;</Col>
            <Col xs lg="2">
              <Button
                className={Styles.buttonView}
                onClick={() => this.prepareDataForNewDigitales()}
                theme="primary" >
                Cargar Imagenes
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }

    return null;

  };

  saveImage = (imageData) => {
    this.setState({ files: imageData.image, tipoDigi: imageData.type, nombreDigi: imageData.name }, () => {
      this.saveDigitales();
    });
  };


  render() {
    console.log('render view functional', 'cant images', this.state.files);
    return (
      <div>
        {!this.state.showForm &&
          <TableDigitales
            loadingData={this.state.loadingData}
            getOneDigitales={this.getOneDigitales}
            data={this.state.digiEncontrado}
            getDigitales={this.getDigitales}
            deleteDigitales={this.deleteDigitales}
            emprendimientoSelected={this.state.emprendimientoSelected}
          />}

        {this.showButtons()}

        {this.state.showForm &&
          <div>
            <InputImage
              saveImage={this.saveImage}
              cancelFormData={this.cancelFormData}
              digitalData={this.state.digitalData}
            />
          </div>
        }
      </div>
    )
  };
};

export default withRouter(UnidadDigitales);
