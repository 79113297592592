import React from "react";
import { Button, Row, Col, Container } from "shards-react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import TableEmprendimientos from "../components/emprendimiento/Table";
import FormEmprendimiento from "../components/emprendimiento/Form";
import Styles from "../components/componentStyles/styles.module.css";
import { Modal } from "react-bootstrap";
import moment from 'moment';
import 'moment/locale/es';

class Emprendimientos extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idSelected: null,
      showFormUnidades: false,
      loadingData: true,
      emprendimientoAmenities: [],
      domicilio: [],
      cantRecorridos: [{ url: '', descripcion: '' }],
      amenities: [],
      aptoProfesional: false,
      tipologiasTxtComercial: '',
      titulo3Barrio: '',
      nuevo_lanzamiento: false,
      nombreEmp: '', friendlyEmp: '', homeDestacado: false, homeEmp: false, empEncontrado: [], domicilio_id: 0, fecha_entrega: '',
      estadoEmprendimiento: '', tituloInicial: '', descripcionInicial: '', tituloTipologia: '', descripcionTipologia: '',
      showForm: false, descripcionBarrio: '', tituloBarrio: '', subtituloBarrio: '', address: '',
      oneEmpEncontrado: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  getAmenities = () => {
    this.setState({}, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/amenities')
        .then((response) => {
          this.setState({ amenities: response.data })
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  setRecorrido = (recorrido, i) => {
    let aux = this.state.cantRecorridos;
    aux[i] = recorrido;
    this.setState({ cantRecorridos: aux })
  }

  nuevoRecorrido = () => {
    let aux = this.state.cantRecorridos;
    let vars = [];
    vars = aux;
    vars.push('');
    console.log('aux', aux);
    this.setState({ cantRecorridos: vars })
  }

  setField = (name, newValue) => {
    this.setState({ [name]: newValue });
  };

  handleChange(nameCheck) {
    console.log('que trae?', nameCheck)
    const newState = {};
    newState[nameCheck] = !this.state[nameCheck];
    this.setState({ ...this.state, ...newState });
  };

  getEmprendimiento = () => {
    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/emprendimientos')
        .then((response) => {
          setTimeout(() => {
            this.setState({ empEncontrado: response.data, loadingData: false })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  componentDidMount() {

    console.log('componentDidMount de ViewEmprendimientos');
    this.getEmprendimiento();
    this.getAmenities();
  };

  saveEmprendimiento = () => {
    if (this.state.idSelected) {
      this.updateEmprendimiento();
    } else {
      this.newEmprendimiento();
    }
  };

  newEmprendimiento = () => {
    axios.post(process.env.REACT_APP_PATH + '/api/emprendimientos', {
      nombre: this.state.nombreEmp,
      friendly_url: this.state.friendlyEmp,
      fecha_entrega: this.state.fecha_entrega,
      amenities: this.state.selectedAmenities,
      estado_emprendimiento: this.state.estadoEmprendimiento,
      home: this.state.homeEmp,
      home_destacado: this.state.homeDestacado,
      domicilio_id: this.state.domicilio_id,
      apto_profesional: this.state.aptoProfesional,
      recorridos: this.state.cantRecorridos,
      address: this.state.address,
      descuento_usuario: this.state.descuento_usuario,
      nuevo_lanzamiento: this.state.nuevo_lanzamiento,
      titulo_inicial: this.state.tituloInicial,
      titulo3_barrio: this.state.titulo3Barrio,
      descripcion_inicial: this.state.descripcionInicial,
      titulo_tipologia: this.state.tituloTipologia,
      descripcion_tipologia: this.state.descripcionTipologia,
      txt_comercial_tipologia: this.state.tipologiasTxtComercial,
      titulo_comercial_tipologia: this.state.tituloComercialTipologia,
      titulo_barrio: this.state.tituloBarrio,
      descripcion_barrio: this.state.descripcionBarrio,
      subtitulo_barrio: this.state.subtituloBarrio
    })
      .then((response) => {
        console.log(response)
        alert('¡Carga con éxito!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No cargado!');
      });
  };

  setAddress = (addressToLoad) => {
    console.log('address to load', addressToLoad);
    this.setState({ address: addressToLoad })
  };

  getOneEmprendimiento = (row) => {

    console.log('id a buscar', row.id);
    axios.get(process.env.REACT_APP_PATH + '/api/oneEmprendimientos', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Trayendo datos', response.data.emprendimientos, response.data.emprendimientoAmenities, response.data.domicilio);
        var emprendimiento = response.data.emprendimientos;
        var emprendimientoAmenities = response.data.emprendimientoAmenities;
        var domicilio = response.data.domicilio;

        let recorridosConDatos = [];
        emprendimiento.recorridos.map(obj => obj.url.length && recorridosConDatos.push(obj));
        recorridosConDatos = recorridosConDatos.length == 0 ? [{ url: '', descripcion: '' }] : recorridosConDatos;
        console.log('recorridos', recorridosConDatos);
        this.setState({
          showForm: true,
          idSelected: emprendimiento.id,
          nombreEmp: emprendimiento.nombre,
          cantRecorridos: recorridosConDatos,
          fecha_entrega: emprendimiento.fecha_entrega,
          friendlyEmp: emprendimiento.friendly_url,
          estadoEmprendimiento: emprendimiento.estado_emprendimiento,
          homeEmp: emprendimiento.home,
          homeDestacado: emprendimiento.home_destacado,
          oneEmpEncontrado: response.data,
          descuento_usuario: emprendimiento.descuento_usuario,
          aptoProfesional: emprendimiento.apto_profesional,
          tipologiasTxtComercial: emprendimiento.txt_comercial_tipologia,
          tituloComercialTipologia: emprendimiento.titulo_comercial_tipologia,
          titulo3Barrio: emprendimiento.titulo3_barrio,
          emprendimientoAmenities: emprendimientoAmenities,
          address: domicilio,
          nuevo_lanzamiento: emprendimiento.nuevo_lanzamiento,
          tituloInicial: emprendimiento.titulo_inicial,
          descripcionInicial: emprendimiento.descripcion_inicial,
          tituloTipologia: emprendimiento.titulo_tipologia,
          descripcionTipologia: emprendimiento.descripcion_tipologia,
          tituloBarrio: emprendimiento.titulo_barrio,
          descripcionBarrio: emprendimiento.descripcion_barrio,
          subtituloBarrio: emprendimiento.subtitulo_barrio
        });
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
  };

  deleteEmprendimiento = (row) => {
    console.log('id a eliminar', row.id);
    axios.delete(process.env.REACT_APP_PATH + '/api/deleteEmprendimientos', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('¡Eliminado!', response);
        //TOCADO POR GASTI
        this.getEmprendimiento();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al Eliminar!')
      });
  };

  updateEmprendimiento = () => {
    let filterRecorridos = [];
    this.state.cantRecorridos.map(eachRecorrido => eachRecorrido.url && filterRecorridos.push(eachRecorrido));

    var dataToUpdate = {

      id: this.state.idSelected,
      nombre: this.state.nombreEmp,
      friendly_url: this.state.friendlyEmp,
      fecha_entrega: this.state.fecha_entrega,
      home: this.state.homeEmp,
      home_destacado: this.state.homeDestacado,
      titulo3_barrio: this.state.titulo3Barrio,
      apto_profesional: this.state.aptoProfesional,
      recorridos: filterRecorridos,
      estado_emprendimiento: this.state.estadoEmprendimiento,
      address: this.state.address,
      nuevo_lanzamiento: this.state.nuevo_lanzamiento,
      txt_comercial_tipologia: this.state.tipologiasTxtComercial,
      titulo_comercial_tipologia: this.state.tituloComercialTipologia,
      amenities: this.state.selectedAmenities,
      descuento_usuario: this.state.descuento_usuario,
      titulo_inicial: this.state.tituloInicial,
      descripcion_inicial: this.state.descripcionInicial,
      titulo_tipologia: this.state.tituloTipologia,
      descripcion_tipologia: this.state.descripcionTipologia,
      titulo_barrio: this.state.tituloBarrio,
      descripcion_barrio: this.state.descripcionBarrio,
      subtitulo_barrio: this.state.subtituloBarrio
    }
    console.log('data a modificar', dataToUpdate)
    axios.put(process.env.REACT_APP_PATH + '/api/updateEmprendimientos', dataToUpdate)
      .then((response) => {
        console.log(response)
        alert('¡Actualizado!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No actualizado!');
      });
  };

  prepareDataForNewEmprendimiento = () => {
    this.setState({
      showForm: true, idSelected: null,
      cantRecorridos: [{ url: '', descripcion: '' }],
      titulo3Barrio: "",
      tipologiasTxtComercial: "",
      aptoProfesional: "",
      id: "", nombreEmp: "", fecha_entrega: "",
      friendlyEmp: "", homeEmp: "", estadoEmprendimiento: "",
      tituloInicial: "", descripcionInicial: "",
      tituloTipologia: "", descripcionTipologia: "",
      tituloBarrio: "", descripcionBarrio: "",
      subtituloBarrio: "", address: "", tituloComercialTipologia: ""
    });
  };

  cancelFormData = () => {
    this.setState({ showForm: false, idSelected: null, showFormUnidades: false });
    this.getEmprendimiento();
  };

  showButtons = () => {
    if (!this.state.showForm) {
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col></Col>
            <Col md="auto">&nbsp;</Col>
            <Col xs lg="2">
              <Button
                className={Styles.buttonView}
                onClick={() => this.prepareDataForNewEmprendimiento()}
                theme="primary" >
                Nuevo Emprendimiento
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }

    return null;

  };

  showFormUnidades = (row) => {
    this.props.history.push('/emprendimiento/unidades/' + row.id);
  };

  showFormDigitales = (row) => {
    this.props.history.push('/emprendimiento/digitales/' + row.id);
  };

  selectAmenities = (selectedAmenities) => {
    this.setState({ selectedAmenities: selectedAmenities });
  };



  hideModalSend = () => {
    this.setState({ sendingMail: false, mailToSend: {}, askMailing: false })
  }

  sendMailInforme = (row) => {

    this.setState({ sendingMail: true })
    axios.post(process.env.REACT_APP_PATH + '/api/mailNuevoInforme', {
      id: row.id
    })
      .then((response) => {
        alert('¡Mail enviado a ' + response.data.email)
        this.hideModalSend();
        this.getEmprendimiento();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al enviar email!')
      });
  }

  askForMailing = (row) => {
    console.log('aca', row)
    this.setState({ askMailing: true, emprendimientoSelected: row });
  }

  render() {
    console.log('render view functional');
    return (
      <div>

        {this.state.askMailing &&
          <Modal show={this.state.askMailing} onHide={() => this.hideModalSend()}>
            <Modal.Header closeButton>
              <Modal.Title>Estás por enviar un email al cliente</Modal.Title>
            </Modal.Header>
            {this.state.emprendimientoSelected.fecha_envio_notificacion ?
              <Modal.Body>El último saldo enviado por esta unidad fue el {this.state.emprendimientoSelected.fecha_envio_notificacion ? moment(this.state.emprendimientoSelected.fecha_envio_notificacion).format('DD/MM/YYYY H:mm') : ''}</Modal.Body>
              :
              <Modal.Body>Todavía no se ha enviado email al cliente por actualización en informe de obra</Modal.Body>
            }
            <Modal.Footer>
              <Button loading={this.state.send} theme="primary" onClick={() => this.sendMailInforme(this.state.emprendimientoSelected)}>{'Enviar'}</Button>
            </Modal.Footer>
          </Modal>
        }

        {!this.state.showForm && <TableEmprendimientos
          loadingData={this.state.loadingData}
          showFormDigitales={this.showFormDigitales}
          showFormUnidades={this.showFormUnidades}
          askForMailing={this.askForMailing}
          getOneEmprendimiento={this.getOneEmprendimiento}
          data={this.state.empEncontrado}
          getEmprendimiento={this.getEmprendimiento}
          deleteEmprendimiento={this.deleteEmprendimiento}
        />}

        {!this.state.showForm && this.showButtons()}

        {/* Emprendimientos form */}
        {this.state.showForm &&
          <FormEmprendimiento
            cancelFormData={this.cancelFormData}
            setAddress={this.setAddress}
            nuevoRecorrido={this.nuevoRecorrido}
            setRecorrido={this.setRecorrido}
            cantRecorridos={this.state.cantRecorridos}
            saveEmprendimiento={this.saveEmprendimiento}
            selectAmenities={this.selectAmenities}
            handleChangeCheckbox={this.handleChange}
            dataAmenitie={this.state.amenities}
            emprendimientoAmenities={this.state.emprendimientoAmenities}
            setField={this.setField}
            emprendimiento={{
              titulo3Barrio: this.state.titulo3Barrio,
              nuevo_lanzamiento: this.state.nuevo_lanzamiento,
              tipologiasTxtComercial: this.state.tipologiasTxtComercial,
              tituloComercialTipologia: this.state.tituloComercialTipologia,
              descuento_usuario: this.state.descuento_usuario,
              aptoProfesional: this.state.aptoProfesional,
              id: this.state.idSelected, nombreEmp: this.state.nombreEmp, fecha_entrega: this.state.fecha_entrega,
              friendlyEmp: this.state.friendlyEmp, homeDestacado: this.state.homeDestacado, homeEmp: this.state.homeEmp, estadoEmprendimiento: this.state.estadoEmprendimiento,
              tituloInicial: this.state.tituloInicial, descripcionInicial: this.state.descripcionInicial,
              tituloTipologia: this.state.tituloTipologia, descripcionTipologia: this.state.descripcionTipologia,
              tituloBarrio: this.state.tituloBarrio, descripcionBarrio: this.state.descripcionBarrio,
              subtituloBarrio: this.state.subtituloBarrio, address: this.state.address
            }}
          />}

      </div>
    )
  };
};

export default withRouter(Emprendimientos);