import React from "react";
import { ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, Row, Col, Card, FormCheckbox, FormTextarea } from "shards-react";
import Styles from "../componentStyles/styles.module.css";
import { Select } from "antd";

const { Option } = Select;

const FormUnidades = ({ selectAmenities, unidadAmenities, dataAmenitie, saveUnidades, setField, unidadSelected, cancelFormData, handleChangeCheckbox }) => {
    console.log('Render formUnidades')
    var amenitiesSelected = unidadAmenities.map(eachSelectd => { return { value: eachSelectd.id, label: eachSelectd.nombre } });

    return (
        <Col className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Card className="mb-4">
                <ListGroup >
                    <ListGroupItem>
                        <Row>
                            <Col>
                                <Form>
                                    <Row>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="descripcionUni">Descripción</label>
                                            <FormTextarea
                                                id="descripcionUni"
                                                placeholder="Descripcion..."
                                                value={unidadSelected.descripcion}
                                                onChange={(newValue) => setField('descripcion', newValue.target.value)}
                                            />
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="unidadUni">Unidad</label>
                                            <FormInput
                                                id="unidadUni"
                                                placeholder="Unidad..."
                                                value={unidadSelected.unidad}
                                                onChange={(newValue) => setField('unidad', newValue.target.value)}
                                            />
                                        </Col>
                                    </Row>

                                    <FormGroup>
                                        <label htmlFor="activoUni">Activo</label>
                                        <FormCheckbox toggle small
                                            id="activoUni"
                                            placeholder="Activo..."
                                            value={unidadSelected.activo}
                                            checked={unidadSelected.activo}
                                            onChange={(e) => { handleChangeCheckbox('activoUni') }}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="mtsUni">Metros Cubiertos</label>
                                        <FormInput
                                            id="mtsUni"
                                            placeholder="Metros cubiertos..."
                                            value={unidadSelected.mts_cubiertos}
                                            onChange={(newValue) => setField('mts_cubiertos', newValue.target.value)}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="mtsNoUni">Metros No Cubiertos</label>
                                        <FormInput
                                            id="mtsNoUni"
                                            placeholder="Metros no cubiertos..."
                                            value={unidadSelected.mts_nocubiertos}
                                            onChange={(newValue) => setField('mts_nocubiertos', newValue.target.value)}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <label htmlFor="amenities">Seleccionar Amenities</label>
                                        <Select
                                            mode="multiple"
                                            defaultValue={amenitiesSelected}
                                            labelInValue
                                            placeholder="Selecciona Amenities..."
                                            filterOption={false}
                                            onChange={(value) => selectAmenities(value)}
                                            style={{ width: '100%' }}
                                        >
                                            {dataAmenitie.map((amenitie, i) =>
                                                <Option value={amenitie.id} key={i}>{amenitie.nombre}</Option>
                                            )}
                                        </Select>
                                    </FormGroup>

                                    <Row>
                                        <Col>
                                            <Button
                                                type="button"
                                                theme="outline-secondary"
                                                onClick={() => cancelFormData(false)}
                                            >
                                                Volver
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                className={Styles.buttonForm}
                                                type="button"
                                                theme="primary"
                                                onClick={() => saveUnidades()}
                                            >
                                                Cargar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup>
            </Card>
        </Col>

    );
}


export default FormUnidades;