export default function () {
  return [
    {
      title: "Mis Emprendimientos",
      to: "/emprendimientos",
      htmlBefore: '<i class="material-icons">house</i>',
      htmlAfter: ""
    },
    {
      title: "Amenities",
      to: "/amenities",
      htmlBefore: '<i class="material-icons">house</i>',
      htmlAfter: ""
    },
    {
      title: "Banners",
      to: "/banners",
      htmlBefore: '<i class="material-icons">photo</i>',
      htmlAfter: ""
    },
    /*{
      title: "Digitales",
      to: "/digitales",
      htmlBefore: '<i class="material-icons">photo</i>',
      htmlAfter: ""
    },*/
    {
      title: "Clientes",
      to: "/clientes",
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: ""
    },
    /*{
      title: "Usuario",
      to: "/usuarios",
      htmlBefore: '<i class="material-icons">person</i>',
      htmlAfter: ""
    },*/
    {
      title: "Consultas",
      to: "/consultas",
      htmlBefore: '<i class="material-icons">announcement</i>',
      htmlAfter: ""
    },
    {
      title: "Cerrar sesión",
      to: "/logout",
      htmlBefore: '<i class="material-icons">logout</i>',
      htmlAfter: ""
    }


  ];
}
