import React from "react";
import { ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, Row, Col, Card, FormCheckbox } from "shards-react";
import Styles from "../componentStyles/styles.module.css";
import InputImage from "../digitales/InputImage";



const FormAmenities = ({ amenities, saveAmenities, setField, cancelFormData, handleFileChange, handleChangeCheckbox }) => {
    console.log('Render formAmenities')

    return (
        <div style={{ marginTop: 25 }}>
            <Col className="main-content-container px-4 pb-4">
                <Card className="mb-4">
                    <ListGroup >
                        <ListGroupItem>
                            <Row>
                                <Col>
                                    <Form>
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="nombreAme">Nombre</label>
                                                <FormInput
                                                    id="nombreAme"
                                                    placeholder="Nombre..."
                                                    value={amenities.nombreAme}
                                                    onChange={(newValue) => setField('nombreAme', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col md="3" className="form-group">
                                                <label htmlFor="activoAme">Activo</label>
                                                <FormCheckbox small toggle
                                                    id="activoAme"
                                                    placeholder="Activo..."
                                                    value={amenities.activoAme}
                                                    checked={amenities.activoAme}
                                                    onChange={(e) => { handleChangeCheckbox('activoAme') }}
                                                />
                                            </Col>
                                            <Col md="3" className="form-group">
                                                <FormGroup>
                                                    <label htmlFor="ordenAme">Orden</label>
                                                    <FormInput
                                                        id="ordenAme"
                                                        placeholder="Orden..."
                                                        value={amenities.ordenAme}
                                                        onChange={(newValue) => setField('ordenAme', newValue.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="form-group">
                                                <FormGroup>
                                                    <label>Icono</label>
                                                    <InputImage
                                                        digitalData={amenities.iconoAme}
                                                        showType={false}
                                                        showName={false}
                                                        showButtonCancel={false}
                                                        saveImage={(dataDigital) => setField('iconoAme', dataDigital)}
                                                        cancelFormData={{}}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button type="button" theme="outline-secondary" onClick={() => cancelFormData()}>Volver</Button>
                                            </Col>
                                            <Col>
                                                <Button className={Styles.buttonForm} type="button" theme="primary" onClick={() => saveAmenities()}>Cargar</Button>
                                            </Col>
                                        </Row>

                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </div>

    );
}


export default FormAmenities;