import React, { Suspense } from "react";
import { Redirect, BrowserRouter as Router, Route, Switch } from "react-router-dom";
import routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import 'antd/dist/antd.css';
import './assets/site.css';
import AuthService from "./utils/AuthService";

const Auth = new AuthService();

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = { estaLogeado: false, searchingNew: false, mostrar: false, showModal: false, modalText: "", desactive: false, myRoutes: [] }
  }

  async componentDidMount() {
    var logeado = await Auth.loggedIn();
    this.setState({ estaLogeado: logeado, mostrar: true });
  }



  loginUser = () => {

  }

  render() {
    // RUTAS ESTATICAS
    var routesByRol = routes.publicRoutes;
    if (!this.state.mostrar) {
      return (<h3>Cargando sistema</h3>)
    } else if (this.state.estaLogeado) {
      routesByRol = Auth.filterRoutes(routes);
    }

    return (
      <Router basename={process.env.REACT_APP_BASENAME || ""} >
        <Suspense fallback={<div>Cargando sistema ...</div>}>
          <Switch>
            {routesByRol.map((route, index) => {
              return route.path &&
                <PrivateRoute
                  estaLogeado={this.state.estaLogeado}
                  loginUser={this.loginUser}
                  key={index}
                  path={route.path}
                  publicRoute={route.public}
                  exact={route.exact}
                  component={((props) => {
                    return (
                      <route.layout>
                        <route.component loginUser={this.loginUser} />
                      </route.layout>
                    );
                  })}
                />
            }
            )}

            <Route component={() => { return (<NoMatch />) }} />
          </Switch>
        </Suspense>
      </Router>)

  }
}

const NoMatch = () => (<Redirect to="/login?nomatch=true" />)
const PrivateRoute = (({ component: Component, publicRoute, loginUser, estaLogeado, ...rest }) => (
  (publicRoute === true || (publicRoute !== true && estaLogeado === true))
    ? <Route {...rest} render={(props) => (<Component  {...props} />)} />
    : <Redirect to="/login" />
))

export default (App);