import React from "react";
import { ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, Row, Col, Card } from "shards-react";
import Styles from "../componentStyles/styles.module.css";



const FormClientes = ({ clientes, saveClientes, setField, cancelFormData }) => {
    console.log('Render formClientes')

    return (
        <div style={{ marginTop: 25 }}>
            <Col className="main-content-container px-4 pb-4">
                <Card className="mb-4">
                    <ListGroup >
                        <ListGroupItem>
                            <Row>
                                <Col>
                                    <Form>
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="nombreCli">Nombre</label>
                                                <FormInput
                                                    id="nombreCli"
                                                    placeholder="Nombre..."
                                                    value={clientes.nombreCli}
                                                    onChange={(newValue) => setField('nombreCli', newValue.target.value)}
                                                />
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <label htmlFor="apellidoCli">Apellido</label>
                                                <FormInput
                                                    id="apellidoCli"
                                                    placeholder="Apellido..."
                                                    value={clientes.apellidoCli}
                                                    onChange={(newValue) => setField('apellidoCli', newValue.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <FormGroup>
                                            <label htmlFor="dniCli">DNI</label>
                                            <FormInput
                                                id="dniCli"
                                                placeholder="Dni..."
                                                type="number"
                                                value={clientes.dniCli}
                                                onChange={(newValue) => setField('dniCli', newValue.target.value)}
                                            />
                                        </FormGroup>
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <FormGroup>
                                                    <label htmlFor="emailCli">E-mail</label>
                                                    <FormInput
                                                        id="emailCli"
                                                        type='email'
                                                        placeholder="E-mail..."
                                                        value={clientes.emailCli}
                                                        onChange={(newValue) => setField('emailCli', newValue.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <FormGroup>
                                                    <label htmlFor="emailCli">Clave</label>
                                                    <FormInput
                                                        id="pass"
                                                        placeholder="password"
                                                        value={clientes.password}
                                                        onChange={(newValue) => setField('password', newValue.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6" className="form-group">
                                                <FormGroup>
                                                    <label htmlFor="emailCli">E-mail 2</label>
                                                    <FormInput
                                                        id="emailCli"
                                                        type='email'
                                                        placeholder="E-mail 2"
                                                        value={clientes.emailCli2}
                                                        onChange={(newValue) => setField('emailCli2', newValue.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="6" className="form-group">
                                                <FormGroup>
                                                    <label htmlFor="emailCli">E-mail 3</label>
                                                    <FormInput
                                                        type='email'
                                                        id="pass"
                                                        placeholder="E-mail 3"
                                                        value={clientes.emailCli3}
                                                        onChange={(newValue) => setField('emailCli3', newValue.target.value)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <label htmlFor="telefonoCli">Telefono</label>
                                            <FormInput
                                                id="telefonoCli"
                                                placeholder="Telefono..."
                                                value={clientes.telefonoCli}
                                                onChange={(newValue) => setField('telefonoCli', newValue.target.value)}
                                            />
                                        </FormGroup>
                                        <Row>
                                            <Col>
                                                <Button
                                                    type="button"
                                                    theme="outline-secondary"
                                                    onClick={() => cancelFormData()}
                                                >
                                                    Volver
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    className={Styles.buttonForm}
                                                    type="button"
                                                    theme="primary"
                                                    onClick={() => saveClientes()}
                                                >
                                                    Cargar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </ListGroupItem>
                    </ListGroup>
                </Card>
            </Col>
        </div>

    );
}


export default FormClientes;