import React from "react";
import { Container, ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, Row, Col, Card, FormSelect } from "shards-react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import TableClienteUnidades from "../components/clienteUnidades/Table";
import FormClienteUnidades from "../components/clienteUnidades/Form";
import Styles from "../components/componentStyles/styles.module.css";
import ModalDelete from "../components/modalComponent/ModalDelete";
import { Modal } from "react-bootstrap";
import moment from 'moment';
import 'moment/locale/es';



class ClienteUnidades extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idSelected: null,
      empSelected: false,
      showFormUnidades: false,
      mailToSend: {},
      askMailing: false,
      sendingMail: false,
      showFormClientes: false,
      cliente_unidades: null,
      unidades: [],
      unidad: [],
      loadingData: true,
      fechaUni: '', montoUni: '', cuotaUni: '', cliUniEncontrada: [], cliEncontrado: [], uniEncontrada: [],
      showForm: false,
      oneCliUniEncontrada: []
    };

  }

  getUnidadDisponible = () => {
    this.setState({}, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/unidadDisponible', {
        /* params: {
           emprendimiento_id: 0,
           cliente: 0
         }*/
      })
        .then((response) => {
          setTimeout(() => {
            this.setState({ unidades: response.data })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  getClienteUnidades = () => {
    console.log('cliente id por params', this.props.match.params.id)

    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/clienteUnidades', {
        params: { cliente_id: this.props.match.params.id }
      })
        .then((response) => {
          setTimeout(() => {
            this.setState({
              data: response.data,
              loadingData: false
            })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  componentDidMount() {

    console.log('componentDidMount de ViewClienteUnidades');
    this.getClienteUnidades();
    this.getUnidadDisponible();
  };

  saveClienteUnidades = () => {
    if (this.state.idSelected) {
      this.updateClienteUnidades();
    } else {
      this.newClienteUnidades();
    }
  };

  newClienteUnidades = () => {
    if (!this.state.empSelected || !this.state.unidadSelected) {
      alert('Faltan datos');
      return false;
    }
    axios.post(process.env.REACT_APP_PATH + '/api/clienteUnidades', {
      unidades_id: this.state.unidadSelected,
      cliente_id: this.props.match.params.id,
      fecha_inicio: this.state.fechaUni,
      monto_entrada: this.state.montoUni,
      cuota_inicial: this.state.cuotaUni
    })
      .then((response) => {
        console.log(response)
        alert('¡Carga con éxito!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No cargado!');
      });
  }

  getOneClienteUnidades = (row) => {
    console.log('id a buscar', row.id);
    axios.get(process.env.REACT_APP_PATH + '/api/oneClienteUnidades', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Trayendo datos', response.data.clienteUnidades);
        var clienteUnidades = response.data.clienteUnidades;
        this.setState({
          showForm: true,
          idSelected: clienteUnidades.id,
          fechaUni: clienteUnidades.fecha_inicio,
          montoUni: clienteUnidades.monto_entrada,
          cuotaUni: clienteUnidades.cuota_inicial,
          oneCliUniEncontrada: response.data,
        });
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
  };

  deleteClienteUnidades = (row) => {
    console.log('id a eliminar', row.id);
    axios.delete(process.env.REACT_APP_PATH + '/api/deleteClienteUnidades', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Borrado', response);
        this.getClienteUnidades();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al Eliminar!')
      });
  };

  setField = (name, newValue) => {
    this.setState({ [name]: newValue });
  };

  prepareDataForNewClienteUnidades = () => {
    this.setState({ showForm: true, idSelected: null });
  }

  cancelFormData = () => {
    this.setState({ showForm: false, idSelected: null });
    this.getClienteUnidades();
  }

  irHaciaPagosDeLaUnidad = (row) => {
    //console.log('row', row);
    this.props.history.push('/cliente/' + row.cliente.id + '/unidad/' + row.unidad.id + '/pagos/');
  }

  cancelBackData = () => {    //<------ para volver a ViewClientes
    this.props.history.push('/clientes');
  }

  showButtons = () => {
    if (!this.state.showForm) {
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col>
              <Button
                className={Styles.buttonBack}
                onClick={() => this.cancelBackData()}
                theme="secondary" >
                Volver
              </Button>
            </Col>
            <Col md="auto">&nbsp;</Col>
            <Col xs lg="2">
              <Button
                className={Styles.buttonView}
                onClick={() => this.prepareDataForNewClienteUnidades()}
                theme="primary" >
                Nueva Unidad
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }

    return null;

  };

  selectUnidad = (unidadSelected) => {
    console.log('ID de unidad qe trae', unidadSelected)
    this.setState({ unidadSelected: unidadSelected });
  }

  selectEmp = (emp) => {
    this.setState({ empSelected: emp });
  }

  mailPagos = (row) => {

    this.setState({ mailToSend: row, askMailing: true })
  }

  sendMail = (row) => {

    this.setState({ sendingMail: true })
    axios.post(process.env.REACT_APP_PATH + '/api/mailSaldo', {
      id: row.id
    })
      .then((response) => {
        let destinosStr = response.data.destinos.map(each => each);
        alert('¡Mail enviado a ' + destinosStr);
        this.hideModalSend();
        this.getClienteUnidades();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al enviar email!')
      });
  }

  hideModalSend = () => {
    this.setState({ sendingMail: false, mailToSend: {}, askMailing: false })
  }

  render() {
    console.log('render view functional ViewClienteUnidades');


    return (
      <div>
        {this.state.askMailing &&
          <Modal show={this.state.askMailing} onHide={() => this.hideModalSend()}>
            <Modal.Header closeButton>
              <Modal.Title>Estás por enviar un email al cliente</Modal.Title>
            </Modal.Header>
            {this.state.mailToSend.fecha_envio_saldo ?
              <Modal.Body>El último saldo enviado por esta unidad fue el {this.state.mailToSend.fecha_envio_saldo ? moment(this.state.mailToSend.fecha_envio_saldo).format('DD/MM/YYYY H:mm') : ''}</Modal.Body>
              :
              <Modal.Body>Todavía no se ha enviado email al cliente por su saldo en esta unidad</Modal.Body>
            }
            <Modal.Footer>
              <Button loading={this.state.send} theme="primary" onClick={() => this.sendMail(this.state.mailToSend)}>{'Enviar'}</Button>
            </Modal.Footer>
          </Modal>
        }

        {!this.state.showForm &&
          <TableClienteUnidades
            data={this.state.data}
            mailPagos={this.mailPagos}
            loadingData={this.state.loadingData}
            getOneClienteUnidades={this.getOneClienteUnidades}
            pagosDeLaUnidad={this.irHaciaPagosDeLaUnidad}
            getClienteUnidades={this.getClienteUnidades}
            deleteClienteUnidades={this.deleteClienteUnidades} />
        }

        {this.showButtons()}

        {this.state.showForm &&
          <div style={{ marginTop: 25 }}>
            <Col className="main-content-container px-4 pb-4">
              <Card className="mb-4">
                <ListGroup >
                  <ListGroupItem>
                    <Row>
                      <Col>
                        <Form>
                          <FormGroup>
                            <label >Seleccionar emprendimiento</label>
                            <FormSelect
                              placeholder="Seleccione emprendimeonto..."
                              onChange={(newValue) => this.selectEmp(newValue.target.value)}
                            >
                              <option>Emprendimiento...</option>
                              {this.state.unidades.emprendimientos.map((each, i) =>
                                <option value={each.emp_id} key={i}>{each.emprendimiento}</option>
                              )}
                            </FormSelect>
                          </FormGroup>

                          {this.state.empSelected &&
                            <FormGroup>
                              <label >Seleccionar unidad</label>
                              <FormSelect
                                placeholder="Seleccione unidad..."
                                onChange={(newValue) => this.selectUnidad(newValue.target.value)}
                              >
                                <option>Selecciona unidad...</option>
                                {this.state.unidades.disponibles.map((unidad, i) => {
                                  if (unidad.emp_id == this.state.empSelected) {
                                    return <option value={unidad.id} key={i}>{unidad.unidad}</option>
                                  }
                                })}
                              </FormSelect>
                            </FormGroup>
                          }


                          <Row>
                            <Col>
                              <Button
                                type="button"
                                theme="outline-secondary"
                                onClick={() => this.cancelFormData()}
                              >
                                Volver
                                                </Button>
                            </Col>
                            <Col>
                              <Button
                                className={Styles.buttonForm}
                                type="button"
                                theme="primary"
                                onClick={() => this.saveClienteUnidades()}
                              >
                                Cargar
                                                </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </Card>
            </Col>
          </div>
        }
      </div>
    )
  };
};


export default withRouter(ClienteUnidades);
