import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { IoIosSearch, IoIosHome, IoIosRefresh, IoIosLock, IoIosUnlock, IoIosMail } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import ModalDelete from "../modalComponent/ModalDelete";

const TableClientes = ({ data, getClientes, getOneClientes, activateUser, deleteClientes, showFormUnidades, loadingData, mailUser }) => {
    console.log('render table Clientes');
    return (
        <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row style={{ paddingLeft: 10 }}>
                                <h6 className="m-0">Clientes</h6>
                                <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => getClientes()} />
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            {loadingData
                                ?
                                <LoadingDataTable />
                                :
                                <TableData
                                    getClientes={getClientes}
                                    getOneClientes={getOneClientes}
                                    showFormUnidades={showFormUnidades}
                                    deleteClientes={deleteClientes}
                                    activateUser={activateUser}
                                    mailUser={mailUser}
                                    data={data}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const LoadingDataTable = () => (
    <div style={{ textAlign: 'center', padding: 30, marginTop: 8 }}>
        <Spinner animation="border" variant="primary" />
        <span style={{ fontSize: 16, marginLeft: 10 }}>Cargando información...</span>
    </div>
)

const TableData = ({ data, showFormUnidades, deleteClientes, getOneClientes, activateUser, mailUser }) => (
    <table className="table mb-0">
        <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">#</th>
                <th scope="col" className="border-0">Nombre</th>
                <th scope="col" className="border-0">Apellido</th>
                <th scope="col" className="border-0">DNI</th>
                <th scope="col" className="border-0">E-mail</th>
                <th scope="col" className="border-0">Telefono</th>
                <th scope="col" className="border-0">Acciones</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, i) => (
                <tr key={i}>
                    <td>{row.id}</td>
                    <td>{row.nombre}</td>
                    <td>{row.apellido}</td>
                    <td>{row.dni}</td>
                    <td>{row.email}</td>
                    <td>{row.telefono}</td>
                    <td>
                        <IoIosHome size={18} title={'Unidades'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => showFormUnidades(row)} />
                        <IoIosSearch size={18} title={'Ver clientes'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => getOneClientes(row)} />
                        <ModalDelete body={'¿Desea eliminar DEFINITIVAMENTE cliente?'} title={'PBG'} delete={() => deleteClientes(row)} />
                        {row.usuario.activo ?
                            <div>
                                <IoIosLock size={18} title={'Desactivar cliente'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => activateUser(row.usuario)} />
                                <IoIosMail size={18} title={'Activar cliente'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => mailUser(row.usuario)} />
                            </div>
                            :
                            <IoIosUnlock size={18} title={'Activar cliente'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => activateUser(row.usuario)} />
                        }

                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default TableClientes;
export { LoadingDataTable };