import React from 'react';
import { ListGroup, ListGroupItem, Form, FormInput, FormGroup, Button, Row, CardHeader, Col, Card, FormSelect } from "shards-react";
import Styles from "../componentStyles/styles.module.css";

class InputImage extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      name: '', path: '', type: '', data: null, image_path: '', fecha_carga: null
    };
  };

  handleFileChange = (event) => {
    const { target } = event;
    const { files } = target;

    console.log('files', files);

    if (files.length) {
      let filesData = [];
      for (var i = 0; i < files.length; i++) {
        var reader = new FileReader();


        let type = files[i].type;
        let name = files[i].name;
        reader.onload = event => {
          filesData.push({ image: event.target.result, type, name });
        };
        reader.readAsDataURL(files[i]);
      }
      if (this.props.showType === false) {
        console.log('digital de amenitie');
        this.props.saveImage(filesData, this.state.fecha_carga);
      }
      console.log('files data', filesData);
      this.setState({ data: filesData })

    }
  };

  sendImageToFather = () => {
    this.props.saveImage({ image: this.state.data, name: this.state.name, type: this.state.type }, this.state.fecha_carga);
  };

  loadType = (e) => this.setState({ type: e.target.value })

  saveName = (txt) => this.setState({ name: txt.target.value })

  cancelData = () => {
    this.props.cancelFormData({ showForm: false, idSelected: null })
  }

  save


  render() {
    console.log('props input image', this.props)
    return (
      <div style={{ marginTop: 25 }}>
        <Col className="main-content-container px-4 pb-4">
          <Card className="mb-4">
            <CardHeader className="border-bottom">
              <Row style={{ paddingLeft: 10 }}>
                <h6 className="m-0">Digital</h6>
              </Row>
            </CardHeader>
            <ListGroup >
              <ListGroupItem>
                <Row>
                  <Col>
                    <Form encType="multipart/form-data">
                      <FormGroup>
                        <FormInput
                          id="car"
                          type="file"
                          multiple={true}
                          accept={"image/*,.pdf"}
                          capture="camera"
                          onChange={(e) => this.handleFileChange(e)}
                        />
                        {this.state.data && <img width={'150px'} src={this.state.data} />}
                        {!this.state.data && this.props.digitalData && this.props.digitalData.image_path != undefined && <img width={'150px'} src={this.props.digitalData.image_path} />}
                      </FormGroup>
                      <Row>
                        {this.props.date !== false && <Col md="4" className="form-group">
                          <FormInput
                            type="date"
                            placeholder="Fecha de carga..."
                            onChange={(newValue) => this.setState({ fecha_carga: newValue.target.value })}
                          />
                        </Col>}
                        {this.props.showName !== false &&
                          <Col>
                            <FormGroup>
                              <FormInput
                                type='text'

                                onChange={txt => this.saveName(txt)}
                                placeholder='Nombre de archivo'
                              />
                            </FormGroup>
                          </Col>
                        }
                        {this.props.showType !== false &&
                          <Col>
                            <FormGroup>
                              <FormSelect onChange={e => this.loadType(e)}>
                                <option>Elige una opción</option>
                                <option value={'Avance de obra'}>Avance de obra</option>
                                <option value={'resumen_avance'}>Resumen avances de obra</option>
                                <option value={'Planos'}>Planos</option>
                                <option value={'Logo'}>Logo (661x174)</option>
                                <option value={'Background'}>Background (3062x1832)</option>
                                <option value={'Barrio'}>Barrio (720x470)</option>
                                <option value={'plantas_comunes'}>Plantas Comunes (720x470)</option>
                                <option value={'barrio_background'}>Barrio Background (3060x1272)</option>
                                <option value={'Render'}>Render</option>
                                <option value={'Brochure'}>Brochure</option>
                                <option value={'mi_unidad'}>Mi unidad (1250x367)</option>
                                <option value={'Tipologia'}>Proyecto Detalle Tipologia (2176x1580)</option>
                                <option value={'emprendimiento_slider'}>Proyecto Home Slider (Home Slider) (1024x1574)</option>
                                <option value={'emprendimiento_detalle_destacada'}>Proyecto Detalle Destacada(1024x1574)</option>
                                <option value={'unidad_slider'}>Unidad slider (Slider en detalle) (3060x1724)</option>
                              </FormSelect>
                            </FormGroup>
                          </Col>
                        }
                      </Row>
                      <Row>
                        {this.props.showButtonCancel !== false &&
                          <Col>
                            <Button
                              type="button"
                              theme="outline-secondary"
                              onClick={() => this.cancelData()}
                            >
                              Volver
                        </Button>
                          </Col>
                        }
                        {this.props.showCargar !== false &&
                          <Col>
                            <Button
                              className={Styles.buttonForm}
                              type="button"
                              theme="primary"
                              onClick={() => this.sendImageToFather()}
                            >
                              Cargar
                        </Button>
                          </Col>}
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </div>
    )
  };
}
export default InputImage;