import React from "react";
import { Button, Container, Row, Col, Card, FormInput, FormSelect } from "shards-react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import TableClienteUnidadPagos from "../components/clienteUnidadPagos/Table";
import FormClienteUnidadPagos from "../components/clienteUnidadPagos/Form";
import Styles from "../components/componentStyles/styles.module.css";
import InputImage from "../components/digitales/InputImage";
import { ListGroup, ListGroupItem, Form, FormGroup } from "react-bootstrap";
import moment from 'moment';
import 'moment/locale/es';


class ClienteUnidadPagos extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      digita: {},
      id: 0,
      moneda: "$",
      vencimiento: null,
      saldo: 0,
      showForm: true,
    };

  }



  componentDidMount() {

    console.log('componentDidMount de ViewClienteUnidadPAgos');
    this.getClienteUnidadPagos();
  };


  getClienteUnidadPagos = () => {
    var cliente_id = this.props.match.params.cliente_id;
    var unidad_id = this.props.match.params.unidad_id;

    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/clienteUnidadPagos', {
        params: { cliente_id: cliente_id, unidad_id: unidad_id }
      })
        .then((response) => {
          setTimeout(() => {
            this.setState({
              clienteUniPagoEncontrado: response.data.pagos,
              saldo: response.data.cliente_unidad.saldo,
              moneda: response.data.cliente_unidad.moneda,
              digital: response.data.cliente_unidad.image_path,
              vencimiento: response.data.cliente_unidad.vencimiento,
              id: response.data.cliente_unidad.id,
              loadingData: false
            })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };
  cancelBackData = () => {    //<------ para volver
    this.props.history.goBack();
  }

  showButtons = () => {
    if (!this.state.showForm) {
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col>
              <Button
                className={Styles.buttonBack}
                onClick={() => this.cancelBackData()}
                theme="secondary" >
                Volver
              </Button>
            </Col>
            <Col md="auto">&nbsp;</Col>
            <Col xs lg="2">
              <Button
                className={Styles.buttonView}
                onClick={() => this.prepareDataForNewClienteUnidadPagos()}
                theme="primary" >
                Nuevo pago
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }

    return null;

  };

  savePago = () => {
    axios.put(process.env.REACT_APP_PATH + '/api/updateClienteUnidades', {
      id: this.state.id,
      digital: this.state.digital,
      moneda: this.state.moneda,
      vencimiento: this.state.vencimiento,
      saldo: this.state.saldo
    })
      .then((response) => {
        console.log(response)
        alert('¡Carga con éxito!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No cargado!');
      });
  }

  backToTable = () => this.setState({ showForm: false })

  showFormPago = () => {
    return (
      <div style={{ marginTop: 25 }}>
        <Col className="main-content-container px-4 pb-4">
          <Card className="mb-4">
            <ListGroup >
              <ListGroupItem>
                <Row>
                  <Col>
                    <Form encType="multipart/form-data">
                      <FormGroup>
                        <label >Moneda</label>
                        <FormSelect
                          placeholder="Moneda"
                          onChange={(newValue) => this.setState({ moneda: newValue.target.value })}
                        >
                          <option selected={this.state.moneda == 'USD' ? true : false} value="USD">USD</option>
                          <option selected={this.state.moneda == '$' ? true : false} value="$">$</option>
                        </FormSelect>
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="observacionUni">Saldo</label>
                        <FormInput
                          value={this.state.saldo}
                          onChange={(newValue) => this.setState({ saldo: newValue.target.value })}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="observacionUni">Vencimiento</label>
                        <FormInput
                          type="date"
                          placeholder="Fecha de vencimiento"
                          value={moment(this.state.vencimiento).format('YYYY-MM-DD')}
                          onChange={(newValue) => this.setState({ vencimiento: newValue.target.value })}
                        />
                      </FormGroup>

                      <FormGroup>
                        <label>Comprobante</label>
                        <InputImage
                          showType={false}
                          showCargar={false}
                          date={false}
                          showName={false}
                          showButtonCancel={false}
                          digitalData={{ image_path: this.state.digital }}
                          saveImage={(dataDigital) => this.setState({ digital: dataDigital })}
                          cancelFormData={{}}
                        />
                      </FormGroup>
                      <Row>
                        <Col>
                          <Button type="button" theme="outline-secondary" onClick={() => this.cancelBackData()} > Volver</Button>
                        </Col>
                        <Col>
                          <Button className={Styles.buttonForm} type="button" theme="primary"
                            onClick={() => this.savePago()}
                          >
                            Cargar
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </Card>
        </Col>
      </div>
    )
  }


  render() {
    console.log('render view functional');
    return (
      <div>

        {this.showButtons()}

        {this.state.showForm && this.showFormPago()}
      </div>
    )
  };
};

export default withRouter(ClienteUnidadPagos);
