import React from "react";
import { withRouter } from "react-router-dom";
import { CardBody, Row, Col, CardHeader, Container, Card, Button } from "shards-react";
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { IoIosSearch, IoIosRefresh, IoIosCamera, IoLogoUsd } from "react-icons/io";
import ModalDelete from "../components/modalComponent/ModalDelete";
import Styles from "../components/componentStyles/styles.module.css";
import FormEmprendimientoUnidades from "../components/unidades/FormEmprendimientoUnidades";



class EmprendimientoUnidades extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      unidades: [],
      unidadSelected: {},
      unidadAmenities: [],
      amenities: [],
      showFormAltaUnidad: false,
      nombreEmp: '', friendlyEmp: '', latitudEmp: '', longitudEmp: '', homeEmp: false, empEncontrado: [],
      descripcionUni: '', unidadUni: '', activoUni: false, mtsUni: '', mtsNoUni: '', tipologiaUni: '', oneUniEncontrada: [],
      disposicion: '', piso: '', superficieAme: '',
      showForm: false,
      oneEmpEncontrado: []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    console.log('componentDidMount de ViewEmprendimientosUnidades');
    this.getEmprendimientoUnidades();
    this.getAmenities();
  }

  getEmprendimientoUnidades = () => {
    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/unidades', {
        params: { emprendimiento_id: this.props.match.params.id }
      }).then((response) => {
        console.log('response', response.data);
        this.setState({ unidades: response.data, loadingData: false })
      }).catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
    })
  }

  deleteUnidad = (row) => {
    console.log('id a eliminar', row.id);
    axios.delete(process.env.REACT_APP_PATH + '/api/deleteUnidades', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Borrado', response);
        this.getEmprendimientoUnidades();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al Borrar!')
      });
  }

  getOneUnidad = (row) => {
    this.setState({ unidadSelected: row, showFormAltaUnidad: true });
    console.log('id a buscar', row.id);
    axios.get(process.env.REACT_APP_PATH + '/api/oneUnidades', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Trayendo datos', response.data.unidades, response.data.unidadAmenities);
        var unidades = response.data.unidades;
        var unidadAmenities = response.data.unidadAmenities;
        this.setState({
          showForm: true,
          idSelected: unidades.id,
          tipologiaUni: unidades.tipologia,
          descripcionUni: unidades.descripcion,
          unidadUni: unidades.unidad,
          disposicion: unidades.disposicion,
          piso: unidades.piso,
          activoUni: unidades.activo,
          mtsUni: unidades.mts_cubiertos,
          mtsNoUni: unidades.mts_nocubiertos,
          oneUniEncontrada: response.data,
          unidadAmenities: unidadAmenities,
          superficieAme: unidades.superficie_amenities
        });
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
  }

  setFormAltaUnidadShow = (value) => {
    this.setState({ showFormAltaUnidad: value, unidadSelected: {} });
    this.getEmprendimientoUnidades();
  }

  showFormDigitales = (row) => {
    this.props.history.push('/unidad/digitales/' + row.id);
  };

  showUnidadCuota = (row) => {
    this.props.history.push('/unidad/cuota/' + row.id);
  }

  cancelBackData = () => {    //<------ para volver a ViewEmprendimientos
    this.props.history.push('/emprendimientos');
  }

  saveUnidades = () => {
    axios.post(process.env.REACT_APP_PATH + '/api/unidades', {
      params: {
        id: this.state.unidadSelected.id,
        emprendimiento_id: this.props.match.params.id,
        descripcion: this.state.unidadSelected.descripcion,
        unidad: this.state.unidadSelected.unidad,
        tipologia: this.state.unidadSelected.tipologia,
        activo: this.state.unidadSelected.activo,
        disposicion: this.state.unidadSelected.disposicion,
        piso: this.state.unidadSelected.piso,
        superficie_amenities: this.state.unidadSelected.superficie_amenities,
        mts_cubiertos: this.state.unidadSelected.mts_cubiertos,
        mts_nocubiertos: this.state.unidadSelected.mts_nocubiertos,
        //amenities: this.state.selectedAmenities
      }
    })
      .then((response) => {
        console.log(response)
        alert('¡Carga con éxito!');
        this.setFormAltaUnidadShow();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No cargado!');
      });
  }

  setField = (name, newValue) => {
    let auxUnidad = this.state.unidadSelected;
    auxUnidad[name] = newValue;
    this.setState({ unidadSelected: auxUnidad });
  }

  handleChange(nameCheck) {
    console.log('que trae?', nameCheck)
    var aux = this.state.unidadSelected;
    aux[nameCheck] = !aux[nameCheck];
    this.setState({ unidadSelected: aux });
  }

  selectAmenities = (selectedAmenities) => {
    this.setState({ selectedAmenities: selectedAmenities });
  }

  getAmenities = () => {
    this.setState({}, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/amenities')
        .then((response) => {
          this.setState({ amenities: response.data })
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };


  render() {
    console.log('render view functional');


    if (this.state.showFormAltaUnidad) {
      return (
        <div>
          <FormEmprendimientoUnidades
            unidadAmenities={this.state.unidadAmenities}
            selectAmenities={this.selectAmenities}
            dataAmenitie={this.state.amenities}
            saveUnidades={this.saveUnidades}
            unidadSelected={this.state.unidadSelected}
            setField={this.setField}
            cancelFormData={this.setFormAltaUnidadShow}
            handleChangeCheckbox={this.handleChange}
            unidades={{
              id: this.state.idSelected, descripcionUni: this.state.descripcionUni, tipologiaUni: this.state.tipologiaUni,
              activoUni: this.state.activoUni, unidadUni: this.state.unidadUni, mtsUni: this.state.mtsUni, mtsNoUni: this.state.mtsNoUni,
              disposicion: this.state.disposicion, piso: this.state.piso, superficieAme: this.state.superficieAme
            }}
          />
        </div>
      )
    } else {
      return (
        <div>
          <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <Row style={{ paddingLeft: 10 }}>
                      <h6 className="m-0">Unidades de emprendimiento</h6>
                      <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }}
                        onClick={() => this.getEmprendimientoUnidades()} />
                    </Row>
                  </CardHeader>
                  <CardBody className="p-0 pb-3">
                    {this.state.loadingData
                      ?
                      <LoadingDataTable />
                      :
                      <TableData
                        showFormDigitales={this.showFormDigitales}
                        deleteUnidad={this.deleteUnidad}
                        getOneUnidad={this.getOneUnidad}
                        showFormUnidades={this.setFormAltaUnidadShow}
                        showUnidadCuota={this.showUnidadCuota}
                        data={this.state.unidades}
                      />
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <ButtonNuevaUnidad setFormAltaUnidadShow={this.setFormAltaUnidadShow} cancelBackData={this.cancelBackData} />
        </div>
      )
    }
  };
};

export default withRouter(EmprendimientoUnidades);


const ButtonNuevaUnidad = ({ setFormAltaUnidadShow, cancelBackData }) => (
  <Container fluid className="main-content-container px-4 pb-4">
    <Row>
      <Col>
        <Button
          className={Styles.buttonBack}
          onClick={() => cancelBackData()}
          theme="secondary" >
          Volver
        </Button>
      </Col>
      <Col md="auto">&nbsp;</Col>
      <Col xs lg="2">
        <Button
          className={Styles.buttonView}
          onClick={() => setFormAltaUnidadShow(true)}
          theme="primary" >
          Nueva unidad
        </Button>
      </Col>
    </Row>
  </Container>
)

const LoadingDataTable = () => (
  <div style={{ textAlign: 'center', padding: 30, marginTop: 8 }}>
    <Spinner animation="border" variant="primary" />
    <span style={{ fontSize: 16, marginLeft: 10 }}>Cargando información...</span>
  </div>
)

const TableData = ({ data, getOneUnidad, deleteUnidad, showFormDigitales, showUnidadCuota }) => (
  <table className="table mb-0" >
    <thead className="bg-light">
      <tr>
        <th scope="col" className="border-0">#</th>
        <th scope="col" className="border-0">Unidad</th>
        <th scope="col" className="border-0">Descripcion</th>
        <th scope="col" className="border-0">Tipología</th>
        <th scope="col" className="border-0">Activo</th>
        <th scope="col" className="border-0">Piso</th>
        <th scope="col" className="border-0">Disposición</th>
        <th scope="col" className="border-0">Superficie Amenities m²</th>
        <th scope="col" className="border-0">Metros Cubiertos</th>
        <th scope="col" className="border-0">Metros No Cubiertos</th>
        <th scope="col" className="border-0">Acciones</th>
      </tr>
    </thead>
    <tbody>
      {data.map((row, i) => (
        <tr key={i}>
          <td>{row.id}</td>
          <td>{row.unidad}</td>
          <td>{row.descripcion}</td>
          <td>{row.tipologia}</td>
          <td>{row.activo}</td>
          <td>{row.piso}</td>
          <td>{row.disposicion}</td>
          <td>{row.superficie_amenities}</td>
          <td>{row.mts_cubiertos}</td>
          <td>{row.mts_nocubiertos}</td>
          <td>
            <IoLogoUsd size={18} title={'Ver cuotas'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => showUnidadCuota(row)} />
            <IoIosCamera size={20} title={'Imágenes'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => showFormDigitales(row)} />
            <IoIosSearch size={18} title={'Modificar'} style={{ cursor: 'pointer', marginLeft: 5 }}
              onClick={() => getOneUnidad(row)} />
            <ModalDelete body={'¿Desea eliminar unidad?'} title={'PBG'} delete={() => deleteUnidad(row)} />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

