import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

//Access View
import Login from "./views/Login";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Emprendimientos from "./views/ViewEmprendimientos";
import Amenities from "./views/ViewAmenities";
import Usuarios from "./views/ViewUsuarios";
import Clientes from "./views/ViewClientes";
import Unidades from "./views/ViewUnidades";
import Digitales from "./views/ViewDigitales";
import UnidadCuotas from "./views/ViewUnidadCuotas";
import ClienteUnidades from "./views/ViewClienteUnidades";
import Banners from "./views/ViewBanners";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Consultas from "./views/ViewConsultas";
import ClienteUnidadPagos from "./views/ViewClienteUnidadPagos";
import EmprendimientoUnidades from "./views/ViewEmprendimientoUnidades";
import EmprendimientoDigitales from "./views/ViewEmprendimientoDigitales";
import UnidadDigitales from "./views/ViewUnidadDigitales";
import LoginLayout from "./layouts/LoginLayout";
import Logout from "./views/Logout";




export default {
  publicRoutes: [
    {
      path: "/login",
      layout: LoginLayout,
      public: true,
      component: Login
    }
  ],
  privateRoutes: [
    {
      path: "/",
      exact: true,
      layout: DefaultLayout,
      component: () => <Redirect to="/emprendimientos" />
    },
    {
      path: "/unidad/digitales/:id",
      layout: DefaultLayout,
      component: UnidadDigitales
    },
    {
      path: "/emprendimiento/digitales/:id",
      layout: DefaultLayout,
      component: EmprendimientoDigitales
    },
    {
      path: "/cliente/:cliente_id/unidad/:unidad_id/pagos/",
      layout: DefaultLayout,
      component: ClienteUnidadPagos
    },
    {
      path: "/emprendimientos",
      layout: DefaultLayout,
      component: Emprendimientos
    },
    {
      path: "/consultas",
      layout: DefaultLayout,
      component: Consultas
    },
    {
      path: "/emprendimiento/unidades/:id",
      layout: DefaultLayout,
      component: EmprendimientoUnidades
    },
    {
      path: "/amenities",
      layout: DefaultLayout,
      component: Amenities
    },
    {
      path: "/usuarios",
      layout: DefaultLayout,
      component: Usuarios
    },
    {
      path: "/clientes",
      layout: DefaultLayout,
      component: Clientes
    },
    {
      path: "/cliente/unidades/:id",
      layout: DefaultLayout,
      component: ClienteUnidades
    },
    {
      path: "/banners",
      layout: DefaultLayout,
      component: Banners
    },
    {
      path: "/unidades",
      layout: DefaultLayout,
      component: Unidades
    },
    {
      path: "/digitales",
      layout: DefaultLayout,
      component: Digitales
    },
    {
      path: "/unidad/cuota/:id",
      layout: DefaultLayout,
      component: UnidadCuotas
    },
    {
      path: "/blog-overview",
      layout: DefaultLayout,
      component: BlogOverview
    },
    {
      path: "/user-profile-lite",
      layout: DefaultLayout,
      component: UserProfileLite
    },
    {
      path: "/add-new-post",
      layout: DefaultLayout,
      component: AddNewPost
    },
    {
      path: "/errors",
      layout: DefaultLayout,
      component: Errors
    },
    {
      path: "/components-overview",
      layout: DefaultLayout,
      component: ComponentsOverview
    },
    {
      path: "/tables",
      layout: DefaultLayout,
      component: Tables
    },
    {
      path: "/logout",
      layout: LoginLayout,
      component: Logout
    }
  ]
};
