import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { IoIosSearch, IoIosTrash, IoIosRefresh } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import ModalDelete from "../modalComponent/ModalDelete";

const TableUnidadCuotas = ({ data, getUnidadCuotas, getOneUnidadCuotas, deleteUnidadCuotas , loadingData}) => {
    console.log('render table unidadCuotas');
    return (
        <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row style={{ paddingLeft: 10 }}>
                                <h6 className="m-0">Cuotas de unidad</h6>
                                <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => getUnidadCuotas()} />
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            {loadingData
                                ?
                                <LoadingDataTable />
                                :
                                <TableData
                                    deleteUnidadCuotas={deleteUnidadCuotas}
                                    getOneUnidadCuotas={getOneUnidadCuotas}
                                    data={data} 
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const LoadingDataTable = () => (
    <div style={{ textAlign: 'center', padding: 30, marginTop: 8 }}>
        <Spinner animation="border" variant="primary" />
        <span style={{ fontSize: 16, marginLeft: 10 }}>Cargando información...</span>
    </div>
)

const TableData = ({data , getOneUnidadCuotas, deleteUnidadCuotas }) => (
    <table className="table mb-0">
        <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">#</th>
                <th scope="col" className="border-0">N° Cuota</th>
                <th scope="col" className="border-0">Valor</th>
               {/* <th scope="col" className="border-0">Interes</th>
                <th scope="col" className="border-0">Desde</th>
                <th scope="col" className="border-0">Hasta</th> */}
                <th scope="col" className="border-0">Acciones</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, i) => (
                <tr key={i}>
                    <td>{row.id}</td>
                    <td>{row.nro_cuota}</td>
                    <td>{row.valor}</td>
                { /*   <td>{row.interes}</td>
                    <td>{row.cuota_desde}</td>
                    <td>{row.cuota_hasta}</td>   */}
                    <td>
                        <IoIosSearch size={18} title={'Ver Cuotas de unidad'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => getOneUnidadCuotas(row)} /> 
                        <ModalDelete body={'¿Desea eliminar plan de cuotas?'} title={'PBG'} delete={() => deleteUnidadCuotas(row)} />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)


export default TableUnidadCuotas;