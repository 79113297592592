import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { IoIosSearch, IoIosRefresh, IoLogoUsd, IoMdMailUnread } from "react-icons/io";
import { Spinner } from "react-bootstrap";
import ModalDelete from "../modalComponent/ModalDelete";
import moment from 'moment';
import 'moment/locale/es';


const TableClienteUnidades = ({ data, pagosDeLaUnidad, mailPagos, getClienteUnidades, getOneClienteUnidades, deleteClienteUnidades, loadingData }) => {

    let cliente = data && data.length ? data[0] : false;
    console.log('render table clienteUnidades', data, 'cliente', cliente);

    return (
        <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row style={{ paddingLeft: 10 }}>
                                {cliente && <h6 className="m-0">Unidades del cliente {cliente.cliente.nombre + ' ' + cliente.cliente.apellido}</h6>}
                                <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => getClienteUnidades()} />
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            {loadingData
                                ?
                                <LoadingDataTable />
                                :
                                <TableData
                                    pagosDelaUnidad={pagosDeLaUnidad}
                                    deleteClienteUnidades={deleteClienteUnidades}
                                    getOneClienteUnidades={getOneClienteUnidades}
                                    mailPagos={mailPagos}
                                    data={data}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const LoadingDataTable = () => (
    <div style={{ textAlign: 'center', padding: 30, marginTop: 8 }}>
        <Spinner animation="border" variant="primary" />
        <span style={{ fontSize: 16, marginLeft: 10 }}>Cargando información...</span>
    </div>
)

const TableData = ({ data, getOneClienteUnidades, mailPagos, deleteClienteUnidades, pagosDelaUnidad }) => (
    <table className="table mb-0">
        <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">#</th>
                <th scope="col" className="border-0">Emprendimiento</th>
                <th scope="col" className="border-0">Unidad</th>
                <th scope="col" className="border-0">Saldo</th>
                <th scope="col" className="border-0">Comprobante</th>
                <th scope="col" className="border-0">Mail enviado</th>
                <th scope="col" className="border-0">Acciones</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, i) => {

                return <tr key={i}>
                    <td>{row.id}</td>
                    <td>{row.emprendimiento.nombre}</td>
                    <td>{row.unidad.unidad}</td>
                    <td>{row.moneda} {row.saldo}</td>
                    <td><a target="_BLANK" href={row.image_path}><img width={200} src={row.image_path} /></a></td>
                    <td>{row.fecha_envio_saldo ? moment(row.fecha_envio_saldo).format('DD/MM/YYYY H:mm') : ''}</td>
                    <td>
                        <IoLogoUsd size={18} title={'Ver Pagos'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => pagosDelaUnidad(row)} />
                        <IoMdMailUnread size={18} title={'Enviar email'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => mailPagos(row)} />
                        <ModalDelete body={'¿Desea eliminar?'} title={'PBG'} delete={() => deleteClienteUnidades(row)} />
                    </td>
                </tr>
            })}
        </tbody>
    </table>
)


export default TableClienteUnidades;