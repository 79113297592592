import React from "react";
import { Button, Container, Row, Col } from "shards-react";
import axios from 'axios';
import TableUsuarios from "../components/usuarios/Table";
import FormUsuarios from "../components/usuarios/Form";
import Styles from "../components/componentStyles/styles.module.css";

class Usuarios extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idSelected: null,
            emailUsu: "", passwordUsu: "", lastLoginUsu: "", activoUsu: false, creadoUsu: "", modificadoUsu: "",
            showForm: false, oneUsuEncontrado: [], usuEncontrado: [],
            loadingData: true
        }
        this.handleChange = this.handleChange.bind(this);
    };

    setField = (name, newValue) => {
        this.setState({ [name]: newValue });
    };

    handleChange(nameCheck) {
        const newState = {};
        newState[nameCheck] = !this.state[nameCheck];
        this.setState({ ...this.state, ...newState });
    }

    getUsuarios = () => {
        this.setState({ loadingData: true }, () => {
            axios.get(process.env.REACT_APP_PATH + '/api/usuarios')
                .then((response) => {
                    setTimeout(() => {
                        this.setState({ usuEncontrado: response.data, loadingData: false })
                    }, 1000);
                })
                .catch(function (error) {
                    console.log(error)
                    alert('¡Error al traer!')
                });
        })
    };

    componentDidMount() {
        console.log('componentDidMount de ViewUsuarios');
        this.getUsuarios();
    };

    saveUsuarios = () => {
        if (this.state.idSelected) {
            this.updateUsuarios();
        } else {
            this.newUsuarios();
        }
    };

    newUsuarios = () => {
        axios.post(process.env.REACT_APP_PATH + '/api/usuarios', {
            email: this.state.emailUsu,
            password: this.state.passwordUsu,
            activo: this.state.activoUsu
        })
            .then((response) => {
                console.log(response)
                alert('¡Carga con éxito!');
            })
            .catch(function (error) {
                console.log(error)
                alert('¡No cargado!');
            });
    };

    getOneUsuarios = (row) => {
        console.log('id a buscar', row.id);
        axios.get(process.env.REACT_APP_PATH + '/api/oneUsuario', {
            params: {
                id: row.id
            }
        })
            .then((response) => {
                console.log('Trayendo datos', response.data.usuarios);
                var usuarios = response.data.usuarios;
                this.setState({
                    showForm: true,
                    idSelected: usuarios.id,
                    emailUsu: usuarios.email,
                    passwordUsu: usuarios.password,
                    lastLoginUsu: usuarios.last_login,
                    activoUsu: usuarios.activo,
                    creadoUsu: usuarios.created_at,
                    modificadoUsu: usuarios.updated_at,
                    oneUsuEncontrado: response.data
                });
            })
            .catch(function (error) {
                console.log(error)
                alert('¡Error al traer!')
            });
    };

    deleteUsuarios = (row) => {
        console.log('id a eliminar', row.id);
        axios.delete(process.env.REACT_APP_PATH + '/api/deleteUsuario', {
            params: {
                id: row.id
            }
        })
            .then((response) => {
                console.log('¡Eliminado!', response);
                this.getUsuarios();
            })
            .catch(function (error) {
                console.log(error)
                alert('¡Error al eliminar!')
            });
    };

    updateUsuarios = () => {
        var dataToUpdate = {
            params: {
                id: this.state.idSelected,
                email: this.state.emailUsu,
                password: this.state.passwordUsu,
                last_login: this.state.lastLoginUsu,
                activo: this.state.activoUsu
            }
        }
        console.log('data a modificar', dataToUpdate)
        axios.put(process.env.REACT_APP_PATH + '/api/updateUsuario', dataToUpdate)
            .then((response) => {
                console.log(response)
                alert('¡Actualizado!');
            })
            .catch(function (error) {
                console.log(error)
                alert('¡No actualizado!');
            });
    };

    prepareDataForNewUsuarios = () => {
        this.setState({ showForm: true, idSelected: null });
    }

    cancelFormData = () => {
        this.setState({ showForm: false, idSelected: null });
        this.getUsuarios();
    }

    showButtons = () => {
        if (!this.state.showForm) {
            return (
                <Container fluid className="main-content-container px-4 pb-4">
                    <Row>
                        <Col></Col>
                        <Col md="auto">&nbsp;</Col>
                        <Col xs lg="2">
                            <Button
                                className={Styles.buttonView}
                                onClick={() => this.prepareDataForNewUsuarios()}
                                theme="primary" >
                                Nuevo Usuario
                            </Button>
                        </Col>
                    </Row>
                </Container>
            )
        }

        return null;

    };

    // FALTA TRAER LAST LOGIN y corregirlo en LUMEN

    render() {
        console.log('render view functional');
        return (
            <div>
                {!this.state.showForm && <TableUsuarios loadingData={this.state.loadingData} getOneUsuarios={this.getOneUsuarios} data={this.state.usuEncontrado} getUsuarios={this.getUsuarios} deleteUsuarios={this.deleteUsuarios} />}

                {!this.state.showForm && this.showButtons()}

                {this.state.showForm && <FormUsuarios saveUsuarios={this.saveUsuarios} setField={this.setField}
                    cancelFormData={this.cancelFormData}
                    handleChangeCheckbox={this.handleChange}
                    usuarios={{
                        emailUsu: this.state.emailUsu, passwordUsu: this.state.passwordUsu, lastLoginUsu: this.state.lastLoginUsu,
                        activoUsu: this.state.activoUsu, creadoUsu: this.state.creadoUsu, modificadoUsu: this.state.modificadoUsu
                    }} />}
            </div>
        )
    };

}

export default Usuarios;