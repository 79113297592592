import React from "react";
import { Button, Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import TableClientes, { LoadingDataTable } from "../components/clientes/Table";
import FormClientes from "../components/clientes/Form";
import Unidades from "./ViewUnidades";
import Styles from "../components/componentStyles/styles.module.css";
import { Table } from "antd";
import { IoIosHome, IoIosLock, IoIosMail, IoIosRefresh, IoIosSearch, IoIosUnlock } from "react-icons/io";
import ModalDelete from "../components/modalComponent/ModalDelete";


class Clientes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idSelected: null,
      loadingData: true,
      password: '',
      nombreCli: '', apellidoCli: '', dniCli: '', emailCli: '', telefonoCli: '', clienteEncontrado: [],
      showForm: false,
      oneClienteEncontrado: [],
      showFormUnidades: false,

      top: 'none',
      bottom: 'bottomRight'

    };

  }

  setField = (name, newValue) => {
    this.setState({ [name]: newValue });
  };

  getClientes = () => {
    //console.log('get clientes', process.env.REACT_APP_PATH);
    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/clientes', {
        params: {
          unidad: this.props.unidad != undefined && this.props.unidad.id ? this.props.unidad.id : 0
        }
      })
        .then((response) => {
          setTimeout(() => {
            this.setState({ clienteEncontrado: response.data, loadingData: false })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  componentDidMount() {

    console.log('componentDidMount de ViewClientes');
    this.getClientes();
  };

  saveClientes = () => {

    this.newClientes();

  };

  newClientes = () => {

    if (this.state.nombreCli.length < 2) {
      alert('Debe ingresar el nombre del cliente');
      return;
    }
    if (this.state.apellidoCli.length < 2) {
      alert('Debe ingresar el apellido del cliente');
      return;
    }
    if (this.state.dniCli.length < 7) {
      alert('Debe ingresar el dni del cliente');
      return;
    }

    if (this.state.emailCli.length < 8) {
      alert('Debe ingresar el email del cliente');
      return;
    }
    if (!this.state.idSelected && this.state.password.length < 2) {
      alert('Debe ingresar una contraseña para el cliente');
      return;
    }



    if (this.state.telefonoCli.length < 6) {
      alert('Debe ingresar el tel del cliente');
      return;
    }



    axios.post(process.env.REACT_APP_PATH + '/api/clientes', {
      nombre: this.state.nombreCli,
      id: this.state.idSelected,
      apellido: this.state.apellidoCli,
      password: this.state.password,
      dni: this.state.dniCli,
      email: this.state.emailCli,
      email_2: this.state.emailCli2,
      email_3: this.state.emailCli3,
      telefono: this.state.telefonoCli,

    })
      .then((response) => {
        if (response.data.original != undefined && response.data.original.status === false) {
          alert(response.data.original.message);
          return;
        }
        alert('¡Carga con éxito!');
        this.cancelFormData();
      })
      .catch(function (error) {
        console.log(error)

      });
  };

  getOneClientes = (row) => {
    console.log('id a buscar', row.id);

    axios.get(process.env.REACT_APP_PATH + '/api/oneCliente', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Trayendo datos', response.data.clientes);
        var clientes = response.data.clientes;
        this.setState({
          showForm: true,
          idSelected: clientes.id,
          nombreCli: clientes.nombre,
          apellidoCli: clientes.apellido,
          password: '',
          dniCli: clientes.dni,
          emailCli: clientes.email,
          emailCli2: clientes.email_2,
          emailCli3: clientes.email_3,
          telefonoCli: clientes.telefono,
          oneClienteEncontrado: response.data
        });
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
  };

  deleteClientes = (row) => {
    console.log('id a eliminar', row.id);
    axios.post(process.env.REACT_APP_PATH + '/api/deleteCliente', { id: row.id })
      .then((response) => {
        console.log('Borrado', response);
        this.getClientes();
      })
      .catch(function (error) {
        console.log(error)
        alert('Error al Borrar')
      });
  };

  prepareDataForNewClientes = () => {
    this.setState({ showForm: true, idSelected: null });
  };

  cancelFormData = () => {
    this.setState({ showForm: false, idSelected: null, showFormUnidades: false });
    this.getClientes();
  };

  showButtons = () => {
    if (!this.state.showForm) {
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col>
            </Col>
            <Col md="auto">&nbsp;</Col>
            <Col xs lg="2">
              <Button
                className={Styles.buttonView}
                onClick={() => this.prepareDataForNewClientes()}
                theme="primary" >
                Nuevo Cliente
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }

    return null;

  };

  showFormUnidades = (row) => {
    this.props.history.push('/cliente/unidades/' + row.id);
  };

  activateUser = (row) => {
    axios.post(process.env.REACT_APP_PATH + '/api/activateUser', {
      id: row.id
    })
      .then((response) => {
        alert(response.data.text)
        this.getClientes();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al cambiar de estado al usuario!')
      });
  }

  mailUser = (row) => {
    axios.post(process.env.REACT_APP_PATH + '/api/userWelcomMail', {
      id: row.id
    })
      .then((response) => {
        alert(response.data.text)
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al enviar email al usuario!')
      });
  }


  render() {
    console.log('render view functional, unidad:', this.props.unidad);



    const columns = [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Nombre',
        dataIndex: 'nombre',
        key: 'nombre',
        onFilter: (value, record) => record.nombre.indexOf(value) === 0,
        sorter: (a, b) => a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase()),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Apellido',
        dataIndex: 'apellido',
        key: 'apellido',
        onFilter: (value, record) => record.apellido.indexOf(value) === 0,
        sorter: (a, b) => a.apellido.toLowerCase().localeCompare(b.apellido.toLowerCase()),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'DNI',
        dataIndex: 'dni',
        key: 'dni',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Teléfono',
        dataIndex: 'telefono',
        key: 'telefono',
      },
      {
        title: 'Acciones',
        dataIndex: 'id',
        key: 'id',
        render: (value, row) => {
          return (
            <div>
              <IoIosHome size={18} title={'Unidades'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => this.showFormUnidades(row)} />
              <IoIosSearch size={18} title={'Ver clientes'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => this.getOneClientes(row)} />
              <ModalDelete body={'¿Desea eliminar DEFINITIVAMENTE cliente?'} title={'PBG'} delete={() => this.deleteClientes(row)} />

              {row.usuario.activo ?
                <div>
                  <IoIosLock size={18} title={'Desactivar cliente'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => this.activateUser(row.usuario)} />
                  <IoIosMail size={18} title={'Activar cliente'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => this.mailUser(row.usuario)} />
                </div>
                :
                <IoIosUnlock size={18} title={'Activar cliente'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => this.activateUser(row.usuario)} />
              }
            </div>

          )
        }
      },
    ];
    return (
      <div>
        {!this.state.showForm && !this.state.showFormUnidades &&
          <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
              <Col>
                <Card small className="mb-4">
                  <CardHeader className="border-bottom">
                    <Row style={{ paddingLeft: 10 }}>
                      <h6 className="m-0">Clientes</h6>
                      <IoIosRefresh size={22}
                        onClick={() => this.getClientes()}
                        title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }} />
                    </Row>
                  </CardHeader>
                  <CardBody className="p-0 pb-3">
                    {this.state.loadingData
                      ? <LoadingDataTable />
                      : <Table
                        pagination={{ defaultCurrent: 1, pageSize: 100, total: this.state.clienteEncontrado.length, position: [this.state.top, this.state.bottom] }}
                        dataSource={this.state.clienteEncontrado}
                        columns={columns} />
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        }

        {!this.state.showForm && !this.state.showFormUnidades && this.showButtons()}

        {this.state.showForm && !this.state.showFormUnidades &&
          <FormClientes
            saveClientes={this.saveClientes}
            setField={this.setField}
            cancelFormData={this.cancelFormData}
            clientes={{ emailCli2: this.state.emailCli2, emailCli3: this.state.emailCli3, password: this.state.password, id: this.state.idSelected, nombreCli: this.state.nombreCli, apellidoCli: this.state.apellidoCli, dniCli: this.state.dniCli, emailCli: this.state.emailCli, telefonoCli: this.state.telefonoCli }} />}
      </div>
    )
  };
};




export default withRouter(Clientes);
