import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { FormInput, Row, Col, Container, Button } from 'shards-react';


const google = window.google;

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cambiarDomicilio: this.props.domicilio.calle != undefined ? false : true,
      mapViewLocation: {
        latitude: this.props.domicilio.latitude != undefined ? this.props.domicilio.latitude : '',
        longitude: this.props.domicilio.longitude != undefined ? this.props.domicilio.longitude : ''
      },
      newAddress: {
        localidad: this.props.domicilio.localidad != undefined ? this.props.domicilio.localidad : '',
        barrio: this.props.domicilio.barrio != undefined ? this.props.domicilio.barrio : ''
      }
    };
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleSelect = address => {
    geocodeByAddress(this.state.address)
      .then(results => {
        var formatted = this.cargaCamposForm(results[0]);
        console.log('address formatted', formatted);
        var mapViewLocation = { latitude: results[0].geometry.location.lat(), latitudeDelta: 0.0095, longitude: results[0].geometry.location.lng(), longitudeDelta: 0.0095 }
        this.setState({  // <---- assync
          newAddress: formatted,
          activeButton: true,
          address: address,
          domIdSelected: 0,
          mapViewLocation: mapViewLocation,
          newAddressStep: 1,
        }, () => { // <--- callback
          this.initMap(mapViewLocation.latitude, mapViewLocation.longitude);
          this.props.setAddress(this.state.newAddress);
        }
        );
      })
      .catch(error => console.error('Error', error));
  };

  initMap(lat, lng) {
    var map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: lat, lng: lng },
      zoom: 14
    });
    new google.maps.Marker({ position: { lat: lat, lng: lng }, map: map });
  };

  cargaCamposForm = (place) => {
    var es_place = false;
    let domicilio = {};
    //Latitud y longitud
    domicilio.latitude = place.geometry.location.lat();
    domicilio.longitude = place.geometry.location.lng();
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      var value = place.address_components[i].short_name;
      if (addressType === "postal_code") {
        domicilio.cp = value;
      }
      if (addressType === "street_number") {
        domicilio.numero = value;
      }

      if (addressType === "sublocality_level_1" || addressType === "sublocality" || addressType === "political") {
        domicilio.barrio = value;
      }
      if (addressType === "route") {
        if (!es_place) {
          domicilio.calle = value;
        } else {
          domicilio.calle = place.name;
        }
      }
      if (addressType === "locality") {
        domicilio.localidad = place.name;
      }
      if (addressType === "locality") {
        domicilio.ciudad = value;
      }
      if (addressType === "administrative_area_level_2") {
        domicilio.partido = value;
      }
      if (addressType === "administrative_area_level_1") {
        var pos_borrar = value.toLowerCase().indexOf("provincia de ");
        if (pos_borrar !== -1) {
          value = value.substring(13);
        }
        domicilio.provincia = value;
      }
      if (addressType === "country") {
        domicilio.pais = place.address_components[i].long_name;
      }
    }
    return domicilio;
  };

  handleInput = (newBarrio) => {
    var aux = this.state.newAddress;
    aux['barrio'] = newBarrio.target.value;
    this.setState({ newAddress: aux });
  }

  handleInputLocalidad = (newLocalidad) => {
    var aux = this.state.newAddress;
    aux['localidad'] = newLocalidad.target.value;
    this.setState({ newAddress: aux });
  }

  sendToGrandPa = () => {
    this.props.setAddress(this.state.newAddress);
  }

  render() {
    var defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(-34.6037389, -58.3815704));
    var options = { types: ['geocode'], bounds: defaultBounds };

    if (this.props.domicilio.id != undefined && !this.state.cambiarDomicilio) {
      return (
        <div>
          <p>{this.props.domicilio.calle + ' ' + this.props.domicilio.numero + ' ' + this.props.domicilio.barrio}</p>
          <Button pill theme="outline-info" value='Cambiar domicilio' onClick={() => this.setState({ cambiarDomicilio: true })}>Cambiar domicilio</Button>
        </div>
      )
    }

    return (
      <div>
        {console.log('ACA DOMICILIO AUTOCOMPLETE', this.props.domicilio)}
        <PlacesAutocomplete
          value={this.state.address}
          onChange={this.handleChange}
          debounce={500}
          searchOptions={options}
          highlightFirstSuggestion={true}
          onSelect={this.handleSelect}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <FormInput
                {...getInputProps({
                  placeholder: 'Ingrese ubicación...',
                  className: 'location-search-input',
                })}
              />
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
        {this.state.mapViewLocation.latitude ?
          <div style={{ marginTop: 15 }}>
            <Row>
              <Col>
                <label>Ingrese barrio</label>
                <FormInput
                  value={this.state.newAddress.barrio}
                  placeholder="Ingrese barrio..."
                  onChange={this.handleInput}
                  onSubmit={this.sendToGrandPa}
                />
              </Col>
              <Col>
                <label>Ingrese localidad</label>
                <FormInput
                  value={this.state.newAddress.localidad}
                  placeholder="Ingrese localidad..."
                  onChange={this.handleInputLocalidad}
                  onSubmit={this.sendToGrandPa}
                />
              </Col>
            </Row>
          </div>
          : null}
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col xs lg="2">&nbsp;</Col>
            <Col md="auto">{this.state.mapViewLocation.latitude ? <div style={{ height: 350, width: 700, marginTop: 15 }} id="map"></div> : null}</Col>
            <Col xs lg="2">&nbsp;</Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default LocationSearchInput;