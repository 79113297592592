import React from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";
import { Spinner } from 'react-bootstrap';
import { IoIosSearch, IoLogoUsd, IoMdPerson, IoIosRefresh } from "react-icons/io";
import ModalDelete from "../modalComponent/ModalDelete";

const TableUnidades = ({ data, getUnidades, cliente, getOneUnidades, emprendimiendo, deleteUnidades, showFormUnidadCuotas, showFormClientes, loadingData }) => {
    console.log('render table Unidades');
    return (
        <Container fluid className="main-content-container px-4 pb-4" style={{ marginTop: 25 }}>
            <Row>
                <Col>
                    <Card small className="mb-4">
                        <CardHeader className="border-bottom">
                            <Row style={{ paddingLeft: 10 }}>
                                <h6 className="m-0">
                                    Unidad de {' '}
                                    {cliente && cliente.nombre + ' ' + cliente.apellido}
                                    {emprendimiendo && emprendimiendo.nombre}
                                </h6>
                                <IoIosRefresh size={22} title='Recargar información' style={{ cursor: 'pointer', marginLeft: 10 }} onClick={() => getUnidades()} />
                            </Row>
                        </CardHeader>
                        <CardBody className="p-0 pb-3">
                            {loadingData
                                ?
                                <LoadingDataTable />
                                :
                                <TableData
                                    deleteUnidades={deleteUnidades}
                                    getOneUnidades={getOneUnidades}
                                    showFormClientes={showFormClientes}
                                    showFormUnidadCuotas={showFormUnidadCuotas}
                                    data={data}
                                />
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

const LoadingDataTable = () => (
    <div style={{ textAlign: 'center', padding: 30, marginTop: 8 }}>
        <Spinner animation="border" variant="primary" />
        <span style={{ fontSize: 16, marginLeft: 10 }}>Cargando información...</span>
    </div>
)

const TableData = ({ data, showFormClientes, showFormUnidadCuotas, getOneUnidades, deleteUnidades }) => (
    <table className="table mb-0">
        <thead className="bg-light">
            <tr>
                <th scope="col" className="border-0">#</th>
                <th scope="col" className="border-0">Descripcion</th>
                <th scope="col" className="border-0">Activo</th>
                <th scope="col" className="border-0">Unidad</th>
                <th scope="col" className="border-0">Metros Cubiertos</th>
                <th scope="col" className="border-0">Metros NoCubiertos</th>
                <th scope="col" className="border-0">Acciones</th>
            </tr>
        </thead>
        <tbody>
            {data.map((row, i) => (
                <tr key={i}>
                    <td>{row.id}</td>
                    <td>{row.descripcion}</td>
                    <td>{row.activo}</td>
                    <td>{row.unidad}</td>
                    <td>{row.mts_cubiertos}</td>
                    <td>{row.mts_nocubiertos}</td>
                    <td>
                        <IoMdPerson size={18} title={'Clientes'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => showFormClientes(row)} />
                        <IoLogoUsd size={18} title={'Cuotas de la Unidad'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => showFormUnidadCuotas(row)} />
                        <IoIosSearch size={18} title={'Unidades'} style={{ cursor: 'pointer', marginLeft: 5 }} onClick={() => getOneUnidades(row)} />
                        <ModalDelete body={'¿Desea eliminar unidad?'} title={'PBG'} delete={() => deleteUnidades(row)} />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
)

export default TableUnidades;