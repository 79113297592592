import React from "react";
import { Button } from "shards-react";
import { Modal } from "react-bootstrap";
import { IoIosTrash } from "react-icons/io";

class ModalDelete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false, setShow: false
        }
    }

    handleShow = () => this.setState({ show: true });

    handleClose = () => this.setState({ show: false });


    render() {
        if (!this.state.show) {
            return <IoIosTrash onClick={() => this.handleShow()} size={20} title={'Eliminar'} style={{ cursor: 'pointer' }} />
        }

        return (
            <div>
                <Modal show={this.state.show}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.props.body}</Modal.Body>
                    <Modal.Footer>
                        <Button theme="outline-secondary" onClick={() => this.handleClose()}>Cancelar</Button>
                        <Button theme="primary" onClick={() => this.props.delete()}>Eliminar</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );

    }
}

const ModalConfirm = ({ visible, title, body, onHide, btnMessage = false }) => (
    <Modal show={visible}>
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
            <Button theme="primary" onClick={() => onHide()}>{btnMessage ? btnMessage : 'Entendido'}</Button>
        </Modal.Footer>
    </Modal>
)

export default ModalDelete;
export { ModalConfirm };
