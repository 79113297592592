import React from "react";
import { Container, Row, Col } from "shards-react";

const LoginLayout = ({ children, ...props }) => {
  return (<Container className='fondoLogin'>
    <Row>
      <Col
        className="main-content"
        md={{ size: 3, offset: 1 }}
        sm={{ size: 10, offset: 1 }}
        tag="main"
      >
        {children}
      </Col>
    </Row>
  </Container>)
}
export default LoginLayout;
