import React from "react";
import { Button, Container, Row, Col } from "shards-react";
import { withRouter } from "react-router-dom";
import axios from 'axios';
import TableUnidadCuotas from "../components/unidadCuotas/Table";
import FormUnidadCuotas from "../components/unidadCuotas/Form";
import Styles from "../components/componentStyles/styles.module.css";


class UnidadCuotas extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idSelected: null,
      nroCuota: '', valorCuota: '', interesCuota: '', desdeCuota: null, hastaCuota: null, unidadCuotaEncontrado: [], uniEncontrada: [],
      showForm: false,
      oneUnidadCuotaEncontrado: [],
      loadingData: true
    };

  }

  getUnidadCuotas = () => {
    this.setState({ loadingData: true }, () => {
      axios.get(process.env.REACT_APP_PATH + '/api/unidadCuotas', {
        params: {
          unidad_id: this.props.match.params.id
        }
      })
        .then((response) => {
          setTimeout(() => {
            this.setState({ unidadCuotaEncontrado: response.data, loadingData: false })
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
          alert('¡Error al traer!')
        });
    })
  };

  componentDidMount() {

    console.log('componentDidMount de ViewUnidadCUOTAS');
    this.getUnidadCuotas();
  };

  saveUnidadCuotas = () => {
    if (this.state.idSelected) {
      this.updateUnidadCuotas();
    } else {
      this.newUnidadCuotas();
    }
  };

  newUnidadCuotas = () => {
    axios.post(process.env.REACT_APP_PATH + '/api/unidadCuotas', {
      params: {
        unidad_id: this.props.match.params.id,
        nro_cuota: this.state.nroCuota,
        valor: this.state.valorCuota,
        //interes: this.state.interesCuota,
        //cuota_desde: this.state.desdeCuota,
        //cuota_hasta: this.state.hastaCuota
      }
    })
      .then((response) => {
        console.log(response)
        alert('¡Carga con éxito!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No cargado!');
      });
  }

  getOneUnidadCuotas = (row) => {
    console.log('id a buscar', row.id);
    axios.get(process.env.REACT_APP_PATH + '/api/oneUnidadCuotas', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('Trayendo datos', response.data.unidadCuotas);
        var unidadCuotas = response.data.unidadCuotas;
        this.setState({
          showForm: true,
          idSelected: unidadCuotas.id,
          nroCuota: unidadCuotas.nro_cuota,
          valorCuota: unidadCuotas.valor,
          //interesCuota: unidadCuotas.interes,
          //desdeCuota: unidadCuotas.cuota_desde,
          //hastaCuota: unidadCuotas.cuota_hasta,
          oneUnidadCuotaEncontrado: response.data
        });
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al traer!')
      });
  };

  deleteUnidadCuotas = (row) => {
    console.log('id a eliminar', row.id);
    axios.delete(process.env.REACT_APP_PATH + '/api/deleteUnidadCuotas', {
      params: {
        id: row.id
      }
    })
      .then((response) => {
        console.log('¡Eliminado!', response);
        this.getUnidadCuotas();
      })
      .catch(function (error) {
        console.log(error)
        alert('¡Error al eliminar!')
      });
  };

  updateUnidadCuotas = () => {
    var dataToUpdate = {
      params: {
        id: this.state.idSelected,
        nro_cuota: this.state.nroCuota,
        valor: this.state.valorCuota,
        //interes: this.state.interesCuota,
        //cuota_desde: this.state.desdeCuota,
        //cuota_hasta: this.state.hastaCuota
      }
    }
    console.log('data a modificar', dataToUpdate)
    axios.put(process.env.REACT_APP_PATH + '/api/updateUnidadCuotas', dataToUpdate)
      .then((response) => {
        console.log(response)
        alert('¡Actualizado!');
      })
      .catch(function (error) {
        console.log(error)
        alert('¡No actualizado!');
      });
  };

  setField = (name, newValue) => {
    this.setState({ [name]: newValue });
  };

  prepareDataForNewUnidadCuotas = () => {
    this.setState({ showForm: true, idSelected: null });
  }

  cancelFormData = () => {
    this.setState({ showForm: false, idSelected: null });
    this.getUnidadCuotas();
  }

  cancelBackData = () => {    //<------ para volver
    this.props.history.goBack();
  }

  showButtons = () => {
    if (!this.state.showForm) {
      return (
        <Container fluid className="main-content-container px-4 pb-4">
          <Row>
            <Col>
              <Button
                className={Styles.buttonBack}
                onClick={() => this.cancelBackData()}
                theme="secondary" >
                Volver
              </Button>
            </Col>
            <Col md="auto">&nbsp;</Col>
            <Col xs lg="2">
              <Button
                className={Styles.buttonView}
                onClick={() => this.prepareDataForNewUnidadCuotas()}
                theme="primary" >
                Nueva cuota
              </Button>
            </Col>
          </Row>
        </Container>
      )
    }

    return null;

  };


  render() {
    console.log('render view functional');
    return (
      <div>
        {!this.state.showForm &&
          <TableUnidadCuotas
            loadingData={this.state.loadingData}
            uniData={this.state.uniEncontrada}
            getOneUnidadCuotas={this.getOneUnidadCuotas}
            data={this.state.unidadCuotaEncontrado}
            getUnidadCuotas={this.getUnidadCuotas}
            deleteUnidadCuotas={this.deleteUnidadCuotas}
          />}

        {!this.state.showForm && this.showButtons()}

        {this.state.showForm &&
          <FormUnidadCuotas
            saveUnidadCuotas={this.saveUnidadCuotas}
            cancelFormData={this.cancelFormData}
            setField={this.setField}
            unidadCuotas={{
              id: this.state.idSelected,
              nroCuota: this.state.nroCuota,
              valorCuota: this.state.valorCuota,
              interesCuota: this.state.interesCuota,
              desdeCuota: this.state.desdeCuota,
              hastaCuota: this.state.hastaCuota
            }}
          />}
      </div>
    )
  };
};


export default withRouter(UnidadCuotas);
